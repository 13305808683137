import round from 'lodash/round';
import findLast from 'lodash/findLast';

import { compact } from 'lodash';
import { PROGRESS_STATE } from '../constants';

export const getIsActivityCompleted = ({ status = PROGRESS_STATE.NOT_AVAILABLE }) =>
  [PROGRESS_STATE.COMPLETED, PROGRESS_STATE.IN_PROGRESS, PROGRESS_STATE.AVAILABLE].includes(status);

export const getInProgressActivity = (progress) => progress.find((p) => p.status === PROGRESS_STATE.IN_PROGRESS);

export const getProgressItemById = (id, progress) => progress?.find((p) => p.id?.includes(id));

export const calculateRatio = (value, total, defaultValue = 0) => (total > 0 ? round(value / total, 2) : defaultValue);

export const getEpisodeMeta = (episodeId, progress) => {
  const progressByEpisode = progress.filter((p) => p.id.includes(episodeId));

  // TODO Refator later. Find the better solution

  // last completed element in specific episode
  const lastCompletedElementId = findLast(progressByEpisode, (p) => p.status === PROGRESS_STATE.COMPLETED)?.id;
  const lastCompletedIdx = progress.findIndex((p) => p.id === lastCompletedElementId);
  const nextModule = progress[lastCompletedIdx + 1];
  const isNextModuleAvailable = nextModule?.status === PROGRESS_STATE.AVAILABLE;

  // Current Episode still playing. Also cover a case when next module available, but haven't started yet
  const isEpisodeActive =
    progressByEpisode.some((p) => p.status === PROGRESS_STATE.IN_PROGRESS) || isNextModuleAvailable;

  const isEpisodeCompleted = progressByEpisode.every((p) => p.status === PROGRESS_STATE.COMPLETED);

  return { isEpisodeActive, isEpisodeCompleted };
};

export const getParsedModuleContent = (content) => {
  if (!content) return [];
  const contentList = [];
  if (content.video) {
    contentList.push({
      id: content.video.sys.id,
      content: content.video.fields,
      type: content.video.sys.contentType.sys.id,
    });
  }
  if (content.puzzle) {
    contentList.push({
      id: content.puzzle.sys.id,
      content: content.puzzle.fields,
      type: content.puzzle.sys.contentType.sys.id,
    });
  }

  if (content.assessment) {
    contentList.push({
      id: content.assessment.sys.id,
      content: content.assessment.fields,
      type: content.assessment.sys.contentType.sys.id,
    });
  }
  return contentList;
};

export const getParsedEpisodes = (episodes) =>
  episodes
    ? episodes
        .map((ep) => {
          const epModules = [];
          if (!ep?.fields) return null; // if episode not valid

          // eslint-disable-next-line no-unused-expressions
          ep.fields?.embeddedModules?.forEach((m) => {
            if (m?.fields?.video) {
              epModules.push({
                id: m.fields.video.sys.id,
                content: m.fields.video.fields,
                type: m.fields.video.sys.contentType.sys.id,
              });
            }
            if (m?.fields?.puzzle) {
              epModules.push({
                id: m.fields.puzzle.sys.id,
                content: m.fields.puzzle.fields,
                type: m.fields.puzzle.sys.contentType.sys.id,
              });
            }

            if (m?.fields?.assessment) {
              epModules.push({
                id: m.fields.assessment.sys.id,
                content: m.fields.assessment.fields,
                type: m.fields.assessment.sys.contentType.sys.id,
              });
            }
          });

          const moduleEpisodeDuration = epModules.reduce((acc, i) => acc + (i.content?.duration ?? 0), 0);
          const startVideoDuration = ep.fields?.startVideo?.fields?.duration ?? 0;
          const endVideoDuration = ep.fields?.endVideo?.fields?.duration ?? 0;

          return {
            id: ep.sys.id,
            episodeContent: {
              title: ep.fields?.title,
              duration: startVideoDuration + moduleEpisodeDuration + endVideoDuration,
            },
            content: {
              startVideo: ep.fields?.startVideo && {
                id: ep.fields.startVideo.sys.id,
                content: ep.fields.startVideo.fields,
                type: ep.fields.startVideo.sys.contentType.sys.id,
              },
              modules: epModules,
              endVideo: ep.fields?.endVideo && {
                id: ep.fields.endVideo.sys.id,
                content: ep.fields.endVideo.fields,
                type: ep.fields.endVideo.sys.contentType.sys.id,
              },
            },
          };
        })
        .filter(Boolean) // value will be null or object. Exclude nullish values
    : [];

export const getContentTranslationValues = (contentTranslations = [], languages = []) => {
  return compact(
    contentTranslations.map((translation) => languages.find((language) => language.value === translation)),
  );
};
