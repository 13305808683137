import filter from 'lodash/filter';
import round from 'lodash/round';
import { CONTENT_TYPES } from '@livingsecurity/shared';

import { getParsedModuleContent, getParsedEpisodes, calculateRatio } from '../../utils';
import { PROGRESS_STATE } from '../../constants';

/* ----------  Static getters  ----------*/
export const getContent = (state) => state.contentViewer.timelineData;

export const getContentType = (state) => state.contentViewer.timelineData?.sys?.contentType?.sys?.id || '';

export const getContentData = (state) => state.contentViewer.timelineData?.fields;

export const getContentTranslations = (state) => state.contentViewer.timelineData?.fields?.translations;

export const getContentLoading = (state) => state.contentViewer.contentLoading;

export const getContentProgress = (state) => state.contentViewer.progress;

export const getError = (state) => state.contentViewer.error;

export const getContentProgressLoading = (state) => state.contentViewer.progressLoading;

export const getContentId = (state) => state.contentViewer.assignmentData?.contentId || '';

export const getCampaignId = (state) => state.contentViewer.assignmentData?.campaignId || '';

export const getAssignmentId = (state) => state.contentViewer.assignmentData?.id || '';

export const getDueDate = (state) => state.contentViewer.assignmentData?.dueDate;

export const getAssignmentProgress = (state) => state.contentViewer.assignmentData?.content;

export const getNextAssignment = (state) => state.contentViewer?.nextAssignment;

export const getContentCheckpoint = (state) => state.contentViewer.assignmentData?.contentCheckpoint;

export const getCompletionDate = (state) => state.contentViewer.assignmentData?.completionDate;

export const getIsRequired = (state) => state.contentViewer.assignmentData?.required;

export const getAssignmentGradeFlag = (state) => state.contentViewer.assignmentData?.required_passing_grade;

export const getCompanyGrade = (state) => state.contentViewer.company?.passing_grade;

export const getCompanyGradeFlag = (state) => state.contentViewer.company?.required_passing_grade;

export const getAnswersList = (state) => state.contentViewer.answers ?? [];

/* ----------  Derrived data  ----------*/

export const getPageStatus = (state) => ({
  contentLoading: getContentLoading(state),
  progressLoading: getContentProgressLoading(state),
  error: state.contentViewer.contentError,
});

export const getContentTitles = (state) => ({
  campaignTitle: state.contentViewer.campaign?.name,
  contentTitle: getContentData(state)?.title || '',
});
export const getBadgeData = (state) => ({ points: getContentData(state)?.points, dueDate: getDueDate(state) });

export const getTotalItemsCount = (state) => getContentProgress(state)?.length ?? 0;

export const getProgressMap = (state) =>
  getContentProgress(state)?.reduce((acc, i) => {
    acc[i.id] = i;
    return acc;
  }, {}) || {};

export const getEpisodesContent = (state) => {
  const episodesRaw = getContentData(state)?.episodes;
  return getParsedEpisodes(episodesRaw);
};

export const getModuleContent = (state) => {
  const moduleContentRaw = getContentData(state);
  return getParsedModuleContent(moduleContentRaw);
};

export const getCompletedItemsCount = (state) => {
  const progress = getContentProgress(state);
  if (!progress) return 0;
  const completedItems = filter(progress, ['status', PROGRESS_STATE.COMPLETED]);
  return completedItems.length;
};

export const getСalculatedProgressValue = (state) => {
  const completedItemsCount = getCompletedItemsCount(state);
  const totalItemsCount = getTotalItemsCount(state);
  return calculateRatio(completedItemsCount, totalItemsCount);
};

export const getCorrectAnswersCount = (state) => {
  const answersList = getAnswersList(state);
  return answersList.filter(Boolean).length;
};

export const getСalculatedAccuracyValue = (state) => {
  const answersList = getAnswersList(state);
  const correctAnswersCount = getCorrectAnswersCount(state);
  return calculateRatio(correctAnswersCount, answersList.length);
};

export const getHasQuiz = (state) => {
  const progress = getContentProgress(state);
  if (!progress) return false;
  return progress.some((p) => p.type === CONTENT_TYPES.ASSESSMENT);
};

export const getPuzzlesScore = (state) => {
  const progress = getContentProgress(state);
  if (!progress) return 0;
  const puzzles = filter(progress, ['type', CONTENT_TYPES.PUZZLE]);
  if (!puzzles.length) return 0;
  const completedPuzzles = filter(puzzles, ['status', PROGRESS_STATE.COMPLETED]);
  const ratio = calculateRatio(completedPuzzles.length, puzzles.length);
  return round(ratio * 100);
};

export const getStats = (state) => {
  return {
    accuracy: round(getСalculatedAccuracyValue(state) * 100),
    progress: round(getСalculatedProgressValue(state) * 100),
    rank: state.contentViewer.leaderboardInfo?.thisUser?.rank,
  };
};

export const getIsLowGrade = (state) => {
  const { accuracy } = getStats(state);
  const assignmentGradeFlag = getAssignmentGradeFlag(state);
  const companyGradeFlag = getCompanyGradeFlag(state);
  const companyGrade = getCompanyGrade(state);
  const hasLowGrade = accuracy < companyGrade;
  return [assignmentGradeFlag, companyGradeFlag, hasLowGrade].every(Boolean);
};

export const getIsProgressSaving = (state) => state.contentViewer.progressSaving;
