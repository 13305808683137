import React from 'react';
import { Slide, toast } from 'react-toastify';
import Img from '../../../common/Img';
import { ERROR_ICON, INFO_ICON, SUCCESS_ICON, WARNING_ICON } from '../../../assets/icons';
import { NOTIFICATION_TYPES } from '../../../_constants';

const DEFAULT_NOTIFICATION = {
  transition: Slide,
  draggable: false,
  hideProgressBar: true,
  autoClose: 3000,
  closeOnClick: true,
};

const getNotificationPayload = (type, title, icon) => {
  switch (type) {
    case NOTIFICATION_TYPES.INFO:
      return {
        icon: icon || INFO_ICON,
        heading: title || 'Info',
      };
    case NOTIFICATION_TYPES.SUCCESS:
      return {
        icon: icon || SUCCESS_ICON,
        heading: title || 'Success',
      };
    case NOTIFICATION_TYPES.WARNING:
      return {
        icon: icon || WARNING_ICON,
        heading: title || 'Warning',
      };
    case NOTIFICATION_TYPES.ERROR:
      return {
        icon: icon || ERROR_ICON,
        heading: title || 'Error',
      };
    default:
      return {};
  }
};

const notificationBody = (type, text, title, customIcon, buttons) => {
  const { icon, heading } = getNotificationPayload(type, title, customIcon);

  return (
    <div className="Toastify__toast-content">
      <Img className="Toastify__toast-icon" size={[20, 20]} src={icon} />
      <div className="Toastify__toast-text-wrapper">
        <h3 className="Toastify__toast-text-heading">{heading}</h3>
        <p className="Toastify__toast-text-content">{text}</p>
      </div>
      {buttons && <div className="Toastify__toast-text-wrapper">{buttons}</div>}
    </div>
  );
};

const info = (notification, props = {}, title, icon, buttons) =>
  toast.info(notificationBody(NOTIFICATION_TYPES.INFO, notification, title, icon, buttons), {
    ...DEFAULT_NOTIFICATION,
    ...props,
  });
const success = (notification, props = {}, title, icon, buttons) =>
  toast.success(notificationBody(NOTIFICATION_TYPES.SUCCESS, notification, title, icon, buttons), {
    ...DEFAULT_NOTIFICATION,
    ...props,
  });
const warning = (notification, props = {}, title, icon, buttons) =>
  toast.warning(notificationBody(NOTIFICATION_TYPES.WARNING, notification, title, icon, buttons), {
    ...DEFAULT_NOTIFICATION,
    ...props,
  });
const error = (notification, props = {}, title, icon, buttons) =>
  toast.error(notificationBody(NOTIFICATION_TYPES.ERROR, notification, title, icon, buttons), {
    ...DEFAULT_NOTIFICATION,
    ...props,
  });
const custom = (notification, props = {}) => {
  toast(notification, {
    ...DEFAULT_NOTIFICATION,
    autoClose: false,
    ...props,
  });
};
const removeAllNotifications = () => toast.dismiss();

export default {
  info,
  success,
  warning,
  error,
  custom,
  removeAllNotifications,
  getNotificationPayload,
};
