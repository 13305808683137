/* istanbul ignore file */
import { useMutation } from 'react-query';

import { useAuth0, httpClient } from '../../lib';

const createJob = (body) => httpClient.post('/notification/send-notification-job', body);
const updateJob = (jobId, body) => httpClient.put(`/notification/send-notification-job/${jobId}`, body);

export const useCreateJob = (mutationOptions) => {
  const {
    user: { tenant_id: tenantId },
  } = useAuth0();

  return useMutation(() => createJob({ tenant_id: tenantId }), mutationOptions);
};

export const useUpdateJob = (mutationOptions) => {
  const {
    user: { email, name },
  } = useAuth0();

  return useMutation(
    ({ jobId, body }) =>
      updateJob(jobId, {
        ...body,
        sent_by: {
          email,
          name,
        },
      }),
    mutationOptions,
  );
};
