import { isNull, isEmpty } from 'lodash';
/**
 * Validate form by checking errors object if it has item from requiredFields array
 * @param  {object} errors={}
 * @param  {array} requiredFields=[]
 *
 * @returns {boolean}
 */

export default (errors = {}, requiredFields = []) => {
  if (isEmpty(errors)) {
    console.warn('Pass error object that contains form fields to check it');
    return false;
  }

  return Object.entries(errors).every(([key, value]) => {
    const isValidField = value === false || (Array.isArray(value) && value.every((item) => item === false));
    if (requiredFields.includes(key)) {
      return isValidField;
    }
    if (isNull(value) || (Array.isArray(value) && value.every((item) => isNull(item)))) {
      return true;
    }
    return isValidField;
  });
};
