import union from 'lodash/union';
import { FILTER_FIELDS } from '../../_constants';

export const getFilterParamsFromItems = (items) =>
  items.reduce((acc, { fields }) => {
    Object.entries(FILTER_FIELDS).forEach(([key, name]) => {
      if (!acc[name]) acc[name] = [];
      if (fields[key]) {
        acc[name] = union(acc[name], Array.isArray(fields[key]) ? fields[key] : [fields[key]]);
      }
    });
    return acc;
  }, {});
