import { combineReducers } from 'redux';
import * as entities from '../_entities';

const EVERYTHING_BEFORE_ENTITY_REGEX = /.+?(?=Entity)/;

const reducers = Object.entries(entities).reduce((acc, [key, value]) => {
  const entityName = key.match(EVERYTHING_BEFORE_ENTITY_REGEX)[0];
  const reducerName = entityName.substring(0, 1).toLowerCase() + entityName.substring(1);
  acc[reducerName] = value.reducer;
  return acc;
}, {});

export default combineReducers(reducers);
