import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { isNil } from 'lodash';
import { URLS } from '@livingsecurity/shared';

export const useQueryParams = () => {
  const { search } = useLocation();
  return queryString.parse(search, { parseBooleans: true });
};

export const useStandalonePlayerCheck = () => {
  const { contentid: contentIdParam, locale: localeParam, personalized } = useQueryParams();
  const { pathname } = useLocation();
  const isStandalonePlayerURL = pathname?.includes(URLS.player) && !!contentIdParam;

  const isStandalonePlayer = isStandalonePlayerURL && isNil(window?.parent?.RXD);
  const isLMS = isStandalonePlayerURL && !!window?.parent?.RXD;

  return {
    isStandalonePlayer,
    isLMS,
    isPlatform: !isStandalonePlayer && !isLMS,
    isContentPersonalized: personalized,
    contentIdParam,
    localeParam,
    rxdService: window?.parent?.RXD,
  };
};
