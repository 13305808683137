import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Select, Button, Heading3, BUTTON_TYPES, clientContentful, CT_LOCALIZATION } from '@livingsecurity/shared';

import { getContentTranslationValues } from '../../utils';

import * as Styled from './styles';

const LanguageSelect = ({ onSelect, contentTranslations }) => {
  const { t } = useTranslation('contentViewer');

  const [languageOptions, setLanguageOptions] = useState([]);
  const [language, setLanguage] = useState(null);

  useEffect(() => {
    (async () => {
      const enFallback = { label: 'English (United States)', value: CT_LOCALIZATION.EN };
      try {
        const locales = await clientContentful.getLocales();
        const options = locales?.items?.map((item) => ({ label: item.name, value: item.code })) ?? [enFallback];
        const availableTranslations = getContentTranslationValues(contentTranslations, options);
        setLanguageOptions(isEmpty(availableTranslations) ? [enFallback] : availableTranslations);
        setLanguage(enFallback.value);
      } catch (e) {
        setLanguageOptions([enFallback]);
        setLanguage(enFallback.value);
      }
    })();
  }, [contentTranslations]);

  const handleLanguageChange = (e) => setLanguage(e.target.value);

  const handleLanguageSelect = () => onSelect(language);

  return (
    <Styled.LanguageSelectWrapper>
      <Heading3>{t('language-select.title')}</Heading3>
      <Styled.Subtitle>{t('language-select.subtitle')}</Styled.Subtitle>
      <Select options={languageOptions} dark value={language} onChange={handleLanguageChange} />
      <Button onClick={handleLanguageSelect} text={t('language-select.select-button')} variant={BUTTON_TYPES.SKY} />
    </Styled.LanguageSelectWrapper>
  );
};

LanguageSelect.propTypes = {
  onSelect: PropTypes.func.isRequired,
  contentTranslations: PropTypes.func.isRequired,
};

LanguageSelect.defaultProps = {};

export default LanguageSelect;
