import styled, { css } from 'styled-components';

export const TimelineRoot = styled.ul`
  height: auto;
  overflow: visible;
  position: relative;
  padding-left: 48px;
  z-index: 2;

  &::before {
    content: ' ';
    position: absolute;
    background: rgba(255, 255, 255, 0.5);
    width: 4px;
    top: 12px;
    left: 20px;
    bottom: 30px;
    border-radius: 10px;
    z-index: 0;
    opacity: 1;
  }
`;

export const TimelineDotRoot = styled.span`
  position: absolute;
  top: 0px;
  left: -35px;
  background: #999;
  width: 18px;
  height: 18px;
  border-radius: 100%;
  z-index: 2;
  transition: 0.8s ease all;
`;

export const TimelineContentRoot = styled.div`
  /* display: flex;
  align-items: center; */
  color: rgba(255, 255, 255, 0.5);
  font-weight: normal;
  opacity: ${(props) => (props.animated ? 1 : 0)};
  visibility: ${(props) => (props.animated ? 'visible' : 'hidden')};
  height: 48px;
  overflow: hidden;
  font-size: 14px;
  transition: 0.8s ease all;
`;

const getColorStyle = (props) => {
  let style = css`
    color: rgba(255, 255, 255, 0.5);
  `;
  if (props.completed)
    style = css`
      color: #fff;
    `;
  if (props.active)
    style = css`
      color: #fff;
      font-weight: bold;
    `;
  return style;
};

const getConnectorStyle = (props) =>
  (props.completed || props.active) && !props.root
    ? css`
        height: 60px;
        top: -50px;
      `
    : css`
        height: 0px;
        top: -20px;
      `;

export const TimelineItemRoot = styled.li`
  list-style: none;
  margin-bottom: ${(props) => (props.big ? '20px' : '8px')};
  cursor: ${(props) => (props.completed ? 'pointer' : 'default')};
  position: relative;
  &::before {
    content: '';
    position: absolute;
    left: -28px;
    width: 4px;
    background: #2d98d4;
    ${(props) => getConnectorStyle(props)};
    transition: 0.8s ease all;
  }

  ${TimelineDotRoot} {
    background: ${(props) => (props.completed || props.active ? '#2d98d4' : '#999')};
    cursor: ${(props) => (props.completed ? 'pointer' : 'default')};
    ${(props) =>
      props.big &&
      css`
        width: 24px;
        height: 24px;
        left: -38px;
        top: 2px;
      `}
    &::before {
      content: ' ';
      position: absolute;
      top: 0px;
      left: 0px;
      bottom: 0px;
      right: 0px;
      width: 18px;
      height: 18px;
      border: 1px solid #fff;
      border-radius: 100%;
      z-index: 1;
      opacity: ${(props) => (props.active ? 1 : 0)};
      transition: 0.8s ease all;
    }
  }

  ${TimelineContentRoot} {
    ${(props) => getColorStyle(props)}
    ${(props) =>
      props.big &&
      css`
        height: 48px;
      `}
  }
`;
