import { useCallback, useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';

import { httpClient, useToggle, showErrorsFromBackend } from '@livingsecurity/shared';

import { ContentViewerEntity } from '../_entities';

const { getContentId, getCampaignId, getAssignmentId } = ContentViewerEntity.selectors;

export const useScreenVisibleState = (defaultScreen = '', screens = []) => {
  const [screen, setScreen] = useState(defaultScreen);

  const screenStates = useMemo(
    () =>
      screens.reduce(
        (acc, scr) => ({
          ...acc,
          [`is${capitalize(scr)}ScreenVisible`]: screen === scr,
          [`show${capitalize(scr)}Screen`]: () => setScreen(scr),
        }),
        {},
      ),
    [screen, screens],
  );

  return {
    screen,
    reset: () => setScreen(defaultScreen),
    ...screenStates,
  };
};

export const useFeedBack = () => {
  const { t } = useTranslation();

  const campaignId = useSelector(getCampaignId);
  const contentId = useSelector(getContentId);
  const assignmentId = useSelector(getAssignmentId);

  const [showFeedBack, toggleFeedback] = useToggle(false);
  const [isFeedbackAlreadySubmitted, setIsFeedbackAlreadySubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    /* istanbul ignore else */
    if (assignmentId && contentId) {
      httpClient
        .get(`/feedback/submitted`, { params: { assignment: assignmentId, content: contentId } })
        .then(() => setIsFeedbackAlreadySubmitted(true))
        .catch(() => setIsFeedbackAlreadySubmitted(false));
    }
  }, [assignmentId, contentId]);

  const handleFeedbackSubmit = useCallback(
    ({ stars: rating, comment }) => {
      setIsSubmitting(true);
      return httpClient
        .post(`/feedback/submit`, {
          assignmentId,
          campaignId,
          contentId,
          rating,
          comment,
          submitted: new Date().toISOString(),
        })
        .catch(() => showErrorsFromBackend(t('messages.error.submitFeedback')))
        .finally(() => setIsSubmitting(false));
    },
    [assignmentId, campaignId, contentId, t],
  );

  return {
    isFeedbackAlreadySubmitted,
    showFeedBack,
    submitFeedBack: handleFeedbackSubmit,
    toggleFeedback,
    isSubmitting,
  };
};
