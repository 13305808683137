import React from 'react';
import PropTypes from 'prop-types';

import * as Styled from './styles';

/* ----------  Timeline  ----------*/

export const Timeline = ({ children, ...props }) => <Styled.TimelineRoot {...props}>{children}</Styled.TimelineRoot>;

/* ----------  Item  ----------*/

export const TimelineItem = ({ children, active, completed, root, ...props }) => (
  <Styled.TimelineItemRoot {...props} root={root} active={active} completed={completed}>
    {children}
  </Styled.TimelineItemRoot>
);

TimelineItem.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  root: PropTypes.bool,
};

TimelineItem.defaultProps = {
  active: false,
  completed: false,
  root: false,
};

/* ----------  Dot  ----------*/

export const TimelineDot = ({ ...props }) => <Styled.TimelineDotRoot {...props} />;

/* ----------  Content  ----------*/

export const TimelineContent = ({ children, animated, ...props }) => (
  <Styled.TimelineContentRoot animated={animated} {...props}>
    {children}
  </Styled.TimelineContentRoot>
);

TimelineContent.propTypes = { animated: PropTypes.bool };
TimelineContent.defaultProps = { animated: false };
