export const SET_INITIAL_PROGRESS = 'SET_INITIAL_PROGRESS';
export const UPDATE_PROGRESS = 'UPDATE_PROGRESS';
export const CLEAR_STATE = 'CLEAR_STATE';
export const UPDATE_ANSWERS_LIST = 'UPDATE_ANSWERS_LIST';
export const UPDATE_ASSIGNMENT_PROGRESS = 'UPDATE_ASSIGNMENT_PROGRESS';

// API Requests

export const SAVE_CONTENT_PROGRESS = 'SAVE_CONTENT_PROGRESS';
export const SAVE_CONTENT_PROGRESS_SUCCESS = 'SAVE_CONTENT_PROGRESS_SUCCESS';
export const SAVE_CONTENT_PROGRESS_FAIL = 'SAVE_CONTENT_PROGRESS_FAIL';

export const LOAD_CONTENT_PROGRESS = 'LOAD_CONTENT_PROGRESS';
export const LOAD_CONTENT_PROGRESS_SUCCESS = 'LOAD_CONTENT_PROGRESS_SUCCESS';
export const LOAD_CONTENT_PROGRESS_FAIL = 'LOAD_CONTENT_PROGRESS_FAIL';

export const LOAD_LEADERBOARD_INFO = 'LOAD_LEADERBOARD_INFO';
export const LOAD_LEADERBOARD_INFO_SUCCESS = 'LOAD_LEADERBOARD_INFO_SUCCESS';
export const LOAD_LEADERBOARD_INFO_FAIL = 'LOAD_LEADERBOARD_INFO_FAIL';

export const LOAD_CAMPAIGN = 'LOAD_CAMPAIGN';
export const LOAD_CAMPAIGN_SUCCESS = 'LOAD_CAMPAIGN_SUCCESS';
export const LOAD_CAMPAIGN_FAIL = 'LOAD_CAMPAIGN_FAIL';

export const FETCH_COMPANY = 'FETCH_COMPANY';
export const FETCH_COMPANY_SUCCESS = 'FETCH_COMPANY_SUCCESS';
export const FETCH_COMPANY_FAIL = 'FETCH_COMPANY_FAIL';

// Contentul API

export const LOAD_CONTENT_TIMELINE = 'LOAD_CONTENT_TIMELINE';
export const LOAD_CONTENT_TIMELINE_SUCCESS = 'LOAD_CONTENT_TIMELINE_SUCCESS';
export const LOAD_CONTENT_TIMELINE_FAIL = 'LOAD_CONTENT_TIMELINE_FAIL';

export const LOAD_NEXT_ASSIGNMENT_CONTENT = 'LOAD_NEXT_ASSIGNMENT_CONTENT';
export const LOAD_NEXT_ASSIGNMENT_CONTENT_SUCCESS = 'LOAD_NEXT_ASSIGNMENT_CONTENT_SUCCESS';
export const LOAD_NEXT_ASSIGNMENT_CONTENT_FAIL = 'LOAD_NEXT_ASSIGNMENT_CONTENT_FAIL';
