import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Badge from '../Badge';

import * as Styled from './styles';

const Badges = ({ isExpanded, points, deadline }) => {
  const { t } = useTranslation('contentViewer');

  return (
    <Styled.BadgeSection animated={isExpanded}>
      <Badge
        name={t ? t('badges.available-points') : 'Available Points'}
        value={points ?? (t ? t('not-applicable') : 'N/A')}
        data-testid="available-points"
      />
      <Badge
        name={t ? t('badges.deadline') : 'Deadline'}
        value={deadline ? moment(deadline).format('DD') : t ? t('not-applicable') : 'N/A'}
        topLine={deadline ? moment(deadline).format('MMM') : t ? t('not-applicable') : 'N/A'}
        data-testid="deadline-block"
      />
    </Styled.BadgeSection>
  );
};

Badges.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  points: PropTypes.number,
  deadline: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Badges.defaultProps = {
  points: null,
  deadline: null,
};

export default Badges;
