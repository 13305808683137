import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, BUTTON_TYPES } from '@livingsecurity/shared';

import * as Styled from './styles';

const NotificationPanel = ({ content, duration, isCompleted, onAction, loading }) => {
  const { t } = useTranslation('contentViewer');
  const completeText = t ? t('complete') : 'Complete';
  const continueText = t ? t('continue') : 'Continue';

  return (
    <Styled.NotificationContainer>
      {!isCompleted && (
        <Styled.NotificationInfo>
          <Styled.NotificationNextText>{t('next')}:</Styled.NotificationNextText>
          <Styled.NotificationNextContent>{content}</Styled.NotificationNextContent>
          <Styled.NotificationNextContentDuration>
            {duration && `${duration} ${t ? t('minutes-abbreviation') : 'min'}`}
          </Styled.NotificationNextContentDuration>
        </Styled.NotificationInfo>
      )}
      <Button
        variant={BUTTON_TYPES.SKY}
        loading={loading}
        onClick={onAction}
        text={isCompleted ? completeText : continueText}
      />
    </Styled.NotificationContainer>
  );
};

NotificationPanel.propTypes = {
  content: PropTypes.string,
  duration: PropTypes.string,
  isCompleted: PropTypes.bool.isRequired,
  onAction: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

NotificationPanel.defaultProps = {
  content: 'N/A',
  duration: '',
  loading: false,
};

export default NotificationPanel;
