import React from 'react';
import { useTranslation } from 'react-i18next';
import { URLS, USER_GROUPS, useAuth0 } from '@livingsecurity/shared';
import * as Styled from './style';

const AdminNavbar = () => {
  const { isUserHasRights } = useAuth0();
  const isDashboard = window.location.href.indexOf(URLS.dashboard) > -1;
  const isAdmin = isUserHasRights([
    USER_GROUPS.LS_ADMIN,
    USER_GROUPS.CUSTOMER_ADMIN,
    USER_GROUPS.TRAINING_DEMO,
    USER_GROUPS.TEAMS_COLLABORATOR,
    USER_GROUPS.TEAMS_CUSTOMER_ADMIN,
    USER_GROUPS.TEAMS_LS_ADMIN,
    USER_GROUPS.UNIFY_CUSTOMER_ADMIN,
    USER_GROUPS.PHISHING_CUSTOMER_ADMIN,
    USER_GROUPS.PHISHING_LS_ADMIN,
  ]);

  const { t } = useTranslation('playerDashboard');

  return (
    <>
      {isDashboard && isAdmin && (
        <Styled.StyledAdminNav>
          <Styled.Link to={URLS.userManagement}>
            <span style={{ marginRight: '8px' }}>
              <i className="fas fa-arrow-left" />
            </span>
            {t('back-to-admin')}
          </Styled.Link>
        </Styled.StyledAdminNav>
      )}
    </>
  );
};

AdminNavbar.propTypes = {};
export default AdminNavbar;
