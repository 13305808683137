import React, { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import notification from '../utils/services/notification';
import ConfirmNotification from '../common/Notifications/ConfirmNotification';

export const useUnsavedChanges = ({ canLeave, leaveCallback = () => {}, approveMessage, useExternalUI }) => {
  const { t } = useTranslation('audience');
  const [savedPathname, setSavedPathname] = useState(null);
  const [leaveApproved, setLeaveApproved] = useState(false);
  const [leaveNotificationOpened, setLeaveNotificationOpened] = useState(false);
  const [redirected, setRedirected] = useState(false);

  const history = useHistory();

  const handleSetLeaveApproved = useCallback(() => {
    setLeaveApproved(true);
    setLeaveNotificationOpened(true);
  }, []);

  const handleClose = useCallback(() => {
    setSavedPathname(null);
    setLeaveApproved(false);
    setLeaveNotificationOpened(false);
  }, []);

  const handleShowLeaveNotification = useCallback(
    (pathname) => {
      setSavedPathname(pathname);
      if (!useExternalUI) {
        notification.custom(
          () => (
            <ConfirmNotification
              message={approveMessage || t('messages.info.leave')}
              onConfirm={handleSetLeaveApproved}
            />
          ),
          {
            onClose: handleClose,
          },
        );

        setLeaveNotificationOpened(true);
      }
    },
    [approveMessage, handleClose, handleSetLeaveApproved, t, useExternalUI],
  );

  const beforeUnloadHandler = useCallback(
    (e) => {
      if (canLeave || leaveApproved) return;
      e.preventDefault();
      e.returnValue = approveMessage;
      return approveMessage;
    },
    [approveMessage, canLeave, leaveApproved],
  );

  useEffect(() => {
    (async () => {
      if (leaveApproved && savedPathname && !redirected) {
        setRedirected(true);
        await leaveCallback();
        history.push(savedPathname);
      }
    })();
  }, [history, leaveCallback, savedPathname, leaveApproved, redirected]);

  useEffect(() => {
    window.addEventListener('beforeunload', beforeUnloadHandler, true);

    history.block(({ pathname, state }) => {
      if (!state && !canLeave && !leaveNotificationOpened && !leaveApproved) handleShowLeaveNotification(pathname);
      return state || canLeave || leaveApproved;
    });

    return () => {
      history.block(() => {});
      window.removeEventListener('beforeunload', beforeUnloadHandler, true);
    };
  }, [handleShowLeaveNotification, history, beforeUnloadHandler, leaveApproved, leaveNotificationOpened, canLeave]);

  return {
    showNotification: !!savedPathname,
    handleLeaveApprove: handleSetLeaveApproved,
    handleClose,
  };
};
