import styled, { css } from 'styled-components';
import { BREAKPOINTS } from '@livingsecurity/shared';
import { TextEllipsis } from '@livingsecurity/shared/dist/assets/styles/mixins';

/* ----------  Header styles  ----------*/

export const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  background-color: ${(props) => (props.animated ? 'transparent' : 'var(--pine-green-two)')};
  color: var(--white);
  padding: ${(props) => (props.animated ? '16px 18px 16px 16px' : '8px 26px 8px 16px')};
  height: ${(props) => (props.animated ? '112px' : '48px')};
  transition: 0.8s ease all;
  width: 100vw;
`;

export const Title = styled.div`
  font-size: ${({ animated }) => (animated ? '46px' : '24px')};
  transition: 0.8s ease all;
  width: 100%;
  line-height: 1;

  ${TextEllipsis}
`;

export const Note = styled.span`
  font-size: 24px;
`;

export const SubTitleBox = styled.div`
  opacity: ${(props) => (props.animated ? 1 : 0)};
  margin-top: ${(props) => (props.animated ? '0px' : '-18px')};
  transition: 0.8s ease all;

  > img {
    height: 30px;
    margin: 5px 0 5px 0;
    transition: height 0.8s, margin 0.8s;

    ${({ animated }) =>
      !animated &&
      css`
        height: 0px;
        margin: 0;
      `}
  }
`;

export const SubTitle = styled.div`
  font-size: 18px;
  width: 100%;
  ${TextEllipsis}
`;

export const HeaderLeft = styled.div`
  width: ${({ isExpanded, isStandalonePlayer }) =>
    isExpanded ? (isStandalonePlayer ? '70%' : '55%') : isStandalonePlayer ? '80%' : '65%'};
`;

export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
`;

/* ----------  Sidebar styles  ----------*/

export const SidebarInner = styled.div`
  position: relative;
  padding-top: 28px;
  height: 0;
  flex: 1 1 auto;
  overflow-x: hidden;
  display: grid;
  grid-template-rows: minmax(0, 1fr) auto;
`;

export const TimelineSection = styled.div`
  padding-left: 22px;
  overflow-x: auto;
`;

export const AssetText = styled.div`
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Duration = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 14.4px;
`;

/* ----------  Content styles  ----------*/

export const ContentView = styled.div`
  height: 0;
  flex-grow: 1;
  /* background-color: ${(props) => (props.animated ? 'var(--dark-blue-two)' : 'var(--pine-green-two)')} !important; */

  video {
    background-color: #162839 !important;
  }
`;

export const ContentInner = styled.div`
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 16px;
  overflow: ${(props) => (props.hideScroll ? 'hidden' : 'auto')};
`;

export const PuzzleContainer = styled.div`
  position: relative;
  overflow: hidden;
  max-width: 1360px;
  min-width: 800px;
  min-height: 400px;
  max-height: 760px;
  width: 100%;
  height: 100%;
`;
