import * as actions from './ContentViewer.actions';
import reducer from './ContentViewer.reducer';
import * as types from './ContentViewer.types';
import * as selectors from './ContentViewer.selectors';

export default {
  actions,
  reducer,
  types,
  selectors,
};
