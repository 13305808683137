import React, { useState } from 'react';

import { clientContentful, PuzzleWithWrapper } from '@livingsecurity/shared';

import puzzleReducer from './reducer';

import * as Styled from './styles';
import { useViewerContext } from '../../utils/context';
import { useFakeLoading } from '../../hooks';

const PuzzleDebug = ({ handlePuzzleEnding, puzzleContent }) => {
  const { locale } = useViewerContext();
  const [localPuzzleContent, setLocalPuzzleContent] = useState(null);

  const { fakeLoading, startFakeLoading, resetLoading } = useFakeLoading();

  const handleLoad = (e) => {
    e.preventDefault();
    startFakeLoading({ infinite: true });

    const cid = e.target?.cid?.value;

    clientContentful
      .getEntry(cid, { locale })
      .then((d) => setLocalPuzzleContent(d?.fields))
      .catch((e) => {
        console.error(`Contentful Error:`, e);
        setLocalPuzzleContent(new Error(`Contentful Error: ${e}`));
      })
      .finally(resetLoading);
  };

  const hasError = localPuzzleContent instanceof Error;

  return (
    <>
      <div
        style={{
          display: 'grid',
          gridAutoFlow: 'column',
          gap: '8px',
          position: 'absolute',
          top: 0,
          left: 8,
          fontSize: 16,
          padding: 5,
          zIndex: 999,
        }}
      >
        <form style={{ display: 'flex' }} onSubmit={handleLoad}>
          <input name="cid" placeholder="Enter contentfulID" style={{ padding: 5, width: 300 }} required />
          <button type="submit" style={{ backgroundColor: 'lightgray', padding: 5 }}>
            Load puzzle
          </button>
        </form>
      </div>
      <Styled.PuzzleContainer>
        {fakeLoading ? (
          <div
            style={{
              color: 'white',
              fontSize: 24,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            Puzzle changing...
          </div>
        ) : (
          <PuzzleWithWrapper
            puzzleContent={localPuzzleContent || (hasError ? {} : puzzleContent)}
            reducer={puzzleReducer}
            handlePuzzleEnding={handlePuzzleEnding}
          />
        )}
      </Styled.PuzzleContainer>
    </>
  );
};

export default PuzzleDebug;
