import { cloneDeep, has, isEmpty } from 'lodash';

import { v4 as uuidv4 } from 'uuid';

import { SESSION_EVENTS, PROGRESS_STATE } from '../constants';

export class LmsService {
  static #rxdService = window?.parent?.RXD;

  static isLMS = () => !isEmpty(this.#rxdService);

  static getStudentId = () => {
    return this.#rxdService?.GetStudentID();
  };

  static getState = () => {
    let lmsState = {};
    try {
      const lmsStateString = this.#rxdService.GetSuspendData();
      lmsState = lmsStateString ? JSON.parse(lmsStateString) : {};
    } catch (error) {
      console.error('Error parsing the progress:', error);
    }

    // check empty
    if (isEmpty(lmsState)) return { sessionId: null, progress: {} };

    // check old structure with progress
    if (!has(lmsState, 'progress') && !isEmpty(lmsState)) return { sessionId: null, progress: lmsState };

    // check old structure with sessionId
    if (!has(lmsState, 'sessionId')) return { sessionId: null, progress: lmsState };

    // structure is valid
    return lmsState;
  };

  static saveProgress = (payload) => {
    const allowedEvents = [SESSION_EVENTS.SESSION_ANSWER_SUBMITTED, SESSION_EVENTS.SESSION_CONTENT_COMPLETED];
    const { event, contentId: id } = payload;

    if (!allowedEvents.includes(event)) return Promise.resolve(true);

    const { progress: previousProgress, sessionId: currentSessionId } = this.getState();
    const isAnswering = event === SESSION_EVENTS.SESSION_ANSWER_SUBMITTED;

    const newProgress = cloneDeep(previousProgress ?? {});

    newProgress[id] = {
      ...(newProgress[id] ?? {}),
      status: isAnswering ? PROGRESS_STATE.IN_PROGRESS : PROGRESS_STATE.COMPLETED,
    };

    if (isAnswering) {
      const { selectedContentId: answerId, questionId, isCorrectAnswer } = payload;
      const submittedAnswers = (newProgress[id]?.submitAnswers ?? []).concat({ questionId, answerId, isCorrectAnswer });
      newProgress[id].submitAnswers = submittedAnswers;
    }

    const stateToSave = JSON.stringify({ sessionId: currentSessionId, progress: newProgress });

    console.log('Saving progress to LMS:', {
      previousProgress,
      newProgress,
      payload,
      progressLength: stateToSave.length,
    });
    this.#rxdService.SetSuspendData(stateToSave);
    return Promise.resolve(true);
  };

  static getSessionId = () => {
    const lmsState = this.getState();
    return lmsState?.sessionId;
  };

  static generateSessionId = () => {
    const { progress, sessionId: currentSessionId } = this.getState();
    if (!currentSessionId) {
      const sessionId = uuidv4();
      const stateToSave = JSON.stringify({ sessionId, progress });
      this.#rxdService.SetSuspendData(stateToSave);
      console.log('sessionId generated:', { sessionId, state: { sessionId, progress, currentSessionId } });
      return sessionId;
    }
    return currentSessionId;
  };

  static resetSession = () => {
    const { progress } = this.getState();
    const stateToSave = JSON.stringify({ sessionId: null, progress });
    this.#rxdService.SetSuspendData(stateToSave);
    console.log('session cleared');
  };

  static setScore = (score) => {
    this.#rxdService.SetScore(score, 100, 0);
  };

  static exitSession = () => {
    this.#rxdService.ConcedeControl();
  };

  static completeSession = () => {
    this.#rxdService.SetPassed();
    this.#rxdService.SetReachedEnd();
    this.#rxdService.ConcedeControl();
  };
}
