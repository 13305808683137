import React from 'react';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { Router, Switch, Route } from 'react-router-dom';
import { ErrorBoundary, URLS, i18n } from '@livingsecurity/shared';
import { store } from './lib';
import ContentViewerPage from './pages/ContentViewerPage';

const ContentViewer = ({ history, flags = {}, locale = {} }) => {
  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <Router history={history}>
          <Switch>
            <Route path={URLS.assignment}>
              <ErrorBoundary scope="Content-viewer-page">
                <ContentViewerPage flags={flags} locale={locale} />
              </ErrorBoundary>
            </Route>
            <Route path={URLS.player}>
              <ErrorBoundary scope="Content-viewer-page">
                <ContentViewerPage flags={flags} locale={locale} standalone />
              </ErrorBoundary>
            </Route>
          </Switch>
        </Router>
      </I18nextProvider>
    </Provider>
  );
};

export default ContentViewer;
