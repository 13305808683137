import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const BadgeSection = styled.div`
  display: flex;
  & > * {
    margin-right: 20px;
  }
  & > *:last-child {
    margin-right: 0;
  }
  opacity: ${(props) => (props.animated ? 1 : 0)};
  transform: translateY(${(props) => (props.animated ? '0px' : '-100px')});
  transition: 0.8s ease all;
`;
