import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Heading1, Heading3, TextEllipsis } from '@livingsecurity/shared';

export const SuccessIcon = styled.svg`
  display: block;
  margin: 0 auto;
`;

export const Container = styled.div`
  max-width: 680px;
  text-align: center;
`;

export const Title = styled(Heading1)`
  color: var(--white);
  font-weight: 400;
  margin-top: 24px;
  span {
    display: block;
  }
`;

export const SubTitle = styled(Heading3)`
  color: var(--white);
  font-weight: 400;
  justify-content: center;
`;

export const Timer = styled.div`
  font-weight: 400;
  font-size: 40px;
  line-height: 120%;
  margin-top: 32px;
`;

export const Actions = styled.div`
  display: flex;
  gap: 16px;
  justify-content: ${({ hasNext }) => (hasNext ? 'space-between' : 'center')};
  align-items: center;
  margin-top: 32px;
`;

export const DashboardLink = styled(Link)`
  color: var(--white);
  text-decoration: underline;
  &:hover {
    color: currentColor;
    text-decoration: underline;
    opacity: 0.8;
  }
`;

export const NextTraining = styled.span`
  grid-area: next-training;

  font-size: 10px;
  font-weight: 700;
  line-height: 160%;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: var(--ice-blue);
`;

export const ContentTitle = styled.span`
  grid-area: title;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: currentColor;

  ${TextEllipsis}
`;

export const Arrow = styled.svg`
  grid-area: arrow;
`;

export const NextAssignment = styled.button`
  width: 280px;
  height: 56px;
  color: var(--white);
  border: 2px solid var(--white);
  border-radius: 6px;
  padding: 8px 16px;
  text-align: left;
  transition: all 0.3s linear;
  background: linear-gradient(to right, var(--white) 50%, transparent 50%);
  background-size: 200% 100%;
  background-position: right;

  display: grid;
  grid-template-areas:
    'next-training next-training'
    'title arrow';
  column-gap: 16px;

  &:hover,
  &:focus-visible {
    background-position: left;
    color: var(--dark-blue-grey);
    ${NextTraining} {
      color: var(--slate-grey);
    }
  }
`;
