import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { httpClient, useAuth0, CT_LOCALIZATION } from '@livingsecurity/shared';
import { ContentViewerEntity } from '../../_entities';
import { useStandalonePlayerCheck, useQueryParams } from '../../hooks/useQueryParams';

import { SessionManager } from '../../lib/SessionManager';

import { ASSIGNMENT_ENVIRONMENT } from '../../constants';

const { getContentId, getCampaignId, getPageStatus } = ContentViewerEntity.selectors;

const {
  loadContentTimeline,
  loadLeaderboardInfo,
  loadCampaign,
  clearState,
  fetchCompany,
  updateAssignmentProgress,
  loadNextAssignmentContent,
} = ContentViewerEntity.actions;

const useLoadPage = () => {
  const dispatch = useDispatch();
  const {
    params: { id: assignmentId },
  } = useRouteMatch();
  const { i18n } = useTranslation();

  const {
    user: { sub: userId, tenant_id: tenantId },
  } = useAuth0();

  const { contentIdParam, localeParam, isStandalonePlayer, isLMS, isPlatform, rxdService } = useStandalonePlayerCheck();
  const contentId = useSelector(getContentId) || contentIdParam;
  const campaignId = useSelector(getCampaignId);
  const { contentLoading, progressLoading, error } = useSelector(getPageStatus);
  const searchParams = useQueryParams();

  const sessionManagerRef = useRef(
    new SessionManager(isLMS ? ASSIGNMENT_ENVIRONMENT.LMS : ASSIGNMENT_ENVIRONMENT.PLATFORM, dispatch, searchParams),
  );

  const sessionManager = sessionManagerRef.current;

  const [locale, setLocale] = useState(isPlatform ? localeParam : localeParam || CT_LOCALIZATION.EN);

  useEffect(() => {
    (async () => {
      if (isLMS) {
        const localeToSet = localeParam || CT_LOCALIZATION.EN;
        setLocale(localeToSet); // need to update locale when URL changes silently (LMS case)
        i18n.changeLanguage(localeToSet);
      }
      if (localeParam || !isPlatform) return undefined; // skip load user locale if we have param or in standalone mode
      try {
        const response = await httpClient.get(`/users/users/${encodeURI(userId)}?tenant_id=${tenantId}`);
        setLocale(response?.user_metadata?.locale || CT_LOCALIZATION.EN);
      } catch (e) {
        setLocale(CT_LOCALIZATION.EN);
      }
    })();
  }, [i18n, isLMS, isPlatform, localeParam, tenantId, userId]);

  useEffect(() => {
    if (isLMS) {
      sessionManager.loadProgress().then((progress) => {
        console.log('LMS progress loaded', { progress });
        dispatch(updateAssignmentProgress(progress));
      });
    }
  }, [dispatch, isLMS, rxdService, sessionManager]);

  useEffect(() => {
    (async () => {
      try {
        if (!contentId || !locale) return;
        if (campaignId) {
          await Promise.all([
            dispatch(loadLeaderboardInfo({ id: campaignId })),
            dispatch(loadContentTimeline({ id: contentId, locale })),
          ]);
        } else {
          dispatch(loadContentTimeline({ id: contentId, locale }));
        }
      } catch (error) {
        console.log('Failed to load page', { error });
      }
    })();

    return () => {
      // Reset state after language is changed
      if (isLMS) {
        dispatch(clearState());
      }
    };
  }, [campaignId, contentId, dispatch, isLMS, locale]);

  useEffect(() => {
    if (assignmentId && locale) {
      dispatch(fetchCompany(tenantId));

      sessionManager.loadProgress(assignmentId).then((assignmentResponse) => {
        dispatch(loadCampaign({ ids: [assignmentResponse.campaignId] }));
        if (assignmentResponse?.nextAssignment?.contentId) {
          dispatch(loadNextAssignmentContent({ ...assignmentResponse?.nextAssignment, locale }));
        }
      });
    }
    return () => {
      if (isStandalonePlayer || isLMS) return;
      dispatch(clearState());
    };
  }, [assignmentId, dispatch, isLMS, isStandalonePlayer, locale, sessionManager, tenantId]);

  return { loading: contentLoading || progressLoading || !locale, error, locale, sessionManager };
};

export default useLoadPage;
