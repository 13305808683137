import React, { Children } from 'react';
import styled from 'styled-components';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--dark-blue-two);
`;

const Container = styled.div`
  flex: 1 0 auto;
  display: flex;
`;

const ContentView = styled.div`
  flex-grow: 1;
  background-color: #162839;

  display: flex;
  flex-direction: column;
  overflow: auto;
  position: relative;
`;

const PageLayout = ({ children }) => {
  const [header, sidebar, content] = Children.toArray(children);
  return (
    <PageContainer>
      {header}
      <Container>
        {sidebar}
        <ContentView>{content}</ContentView>
      </Container>
    </PageContainer>
  );
};

PageLayout.propTypes = {};

export default PageLayout;
