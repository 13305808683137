import styled from 'styled-components';

export const BadgeContainer = styled.div`
  border-radius: 8px;
  background-color: ${(props) => props.color};
  padding: 6px;
  text-align: center;
  line-height: 1;
  color: #ffffff;
  min-width: 80px;
  min-height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const BadgeValue = styled.div`
  font-size: 36px;
`;

export const BadgeName = styled.div`
  font-size: 9px;
  margin-top: 6px;
`;

export const BadgeTop = styled.div`
  font-size: 13px;
  letter-spacing: 2.86px;
  text-transform: uppercase;
`;
