import React, { useState, memo, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, matchPath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth0, URLS, Button, Img, ARROW_ICON, USER_GROUPS, getPriorityGroup } from '@livingsecurity/shared';
import { SidebarDropdown, TeamsSidebar } from 'components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getRoutes } from './constants';
import * as Styled from './styles';
import pkg from '../../../../package.json';

export const APP_TYPE = {
  TRAINING: 'TRAINING',
  TEAMS: 'TEAMS',
  UNIFY: 'UNIFY',
  PHISHING: 'PHISHING',
};

function Sidebar({ app, tokenStatus, currentRoute }) {
  const { t } = useTranslation('sidebar');
  const { adminSidebarAnalytics, showPlatformAppSwitcher } = useFlags();

  const { pathname } = useLocation();
  const [isOpen, setOpen] = useState(true);
  const toggleOpen = () => setOpen((prev) => !prev);

  const { isImpersonate, userGroups } = useAuth0();
  const ROUTES = useMemo(() => getRoutes(t), [t]);

  const displayItem = (routeId) => {
    if (routeId === 'analytics') {
      return !adminSidebarAnalytics;
    } else if (routeId === 'settings') {
      return showPlatformAppSwitcher;
    }
    return true;
  };

  const sidebarShown =
    (![USER_GROUPS.LS_ADMIN, USER_GROUPS.PARTICIPANT].includes(getPriorityGroup(userGroups)) || isImpersonate) &&
    tokenStatus;

  if (app === APP_TYPE.TEAMS) {
    return <TeamsSidebar currentRoute={currentRoute} />;
  } else if (app === APP_TYPE.UNIFY || app === APP_TYPE.PHISHING) {
    return null;
  } else if (!sidebarShown) {
    return null;
  }

  return (
    <Styled.Wrapper id="adminSidebar" dark={isImpersonate} isOpen={isOpen}>
      <Styled.Trigger data-testid="expand sidebar" aria-label="expand sidebar" onClick={toggleOpen}>
        <Styled.TriggerImage src={ARROW_ICON} alt="arrow" isOpen={isOpen} />
      </Styled.Trigger>
      <span
        aria-hidden="true"
        data-version={pkg?.version}
        style={{ position: 'absolute', bottom: '10px', color: 'white', opacity: 0, cursor: 'pointer' }}
        onClick={() => {
          const styles = document.querySelector('[data-version]')?.style ?? {};
          styles.opacity = +styles.opacity ? '0' : '0.1';
        }}
      >
        Version: {pkg?.version ?? 'N/A'}
      </span>
      <Styled.ContentWrapper isOpen={isOpen}>
        <Styled.List>
          {ROUTES.map((route, id) => {
            const allowedGroups = route.groups.filter((value) => userGroups.includes(value));
            return !!allowedGroups.length && route.nestedRoutes && displayItem(route.id) ? (
              <Styled.ListItem key={id}>
                <SidebarDropdown dark={isImpersonate} userGroups={userGroups} {...route} />
              </Styled.ListItem>
            ) : (
              !!allowedGroups.length && (
                <Styled.ListItem
                  isActiveRoute={matchPath(pathname, { path: route.path })}
                  withPadding
                  key={id}
                  data-testid={`${route.title}-menu-item`}
                >
                  {displayItem(route.id) && (
                    <Styled.Link to={{ pathname: route.link, state: { from: pathname } }} aria-label={route.title}>
                      <Img size={[28, 28]} src={route.icon} />
                      {route.title}
                    </Styled.Link>
                  )}
                </Styled.ListItem>
              )
            );
          })}
        </Styled.List>
        {isImpersonate && (
          <Styled.ImpersonateBtn>
            <Link to={URLS.cancelImpersonate}>
              <Button text={t('exit')} data-testid="exit" aria-label="exit" />
            </Link>
          </Styled.ImpersonateBtn>
        )}
      </Styled.ContentWrapper>
    </Styled.Wrapper>
  );
}

Sidebar.propTypes = {
  companyInfo: PropTypes.instanceOf(Object),
  app: PropTypes.oneOfType(APP_TYPE),
  tokenStatus: PropTypes.bool.isRequired,
  currentRoute: PropTypes.string,
};

Sidebar.defaultProps = {
  companyInfo: null,
  app: APP_TYPE.TRAINING,
  currentRoute: '',
};

export default memo(Sidebar);
