import React, { lazy, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { LazyLoad, URLS, ContextualHelpModal, FeedbackModal, useToggle } from '@livingsecurity/shared';

import { AuthorizedLayout } from 'components';

const AnalyticsComponent = lazy(() => import('./Analytics'));

const Analytics = (props) => {
  const { pathname } = useLocation();

  const { t } = useTranslation(['reporting', 'shared']);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  const [helpContext, setHelpContext] = useState({ main: null, secondary: null });

  const [emailReportModalIsOpen, toggleEmailReportModalIsOpen] = useToggle(false);

  const toggleContextualHelp = (main, secondary) => {
    if (main) {
      setHelpContext((prevState) => ({
        ...prevState,
        main,
      }));
    }

    if (secondary) {
      setHelpContext((prevState) => ({
        ...prevState,
        secondary,
      }));
    } else {
      setHelpContext((prevState) => ({
        ...prevState,
        secondary: null,
      }));
    }
    setIsHelpModalOpen(!isHelpModalOpen);
  };

  return (
    <>
      {isHelpModalOpen && (
        <ContextualHelpModal
          onClose={() => setIsHelpModalOpen(false)}
          mainContent={helpContext.main}
          secondaryContent={helpContext.secondary}
          title={t('graphs.contextual-help.title')}
          mainTabTitle={t('graphs.contextual-help.tab1')}
          drillDownTabTitle={t('graphs.contextual-help.tab2')}
        />
      )}
      {emailReportModalIsOpen && (
        <FeedbackModal
          content={t('shared:messages.downloads.notification.success')}
          onClose={() => toggleEmailReportModalIsOpen(false)}
        />
      )}
      <AuthorizedLayout
        withBottomPaddingForHubspot={
          pathname === URLS.participants || pathname === URLS.questions || pathname === URLS.campaigns
        }
        withoutPadding
      >
        <LazyLoad
          component={AnalyticsComponent}
          {...props}
          toggleFeedbackModal={toggleEmailReportModalIsOpen}
          toggleContextualHelp={toggleContextualHelp}
        />
      </AuthorizedLayout>
    </>
  );
};

export default Analytics;
