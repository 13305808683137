import { useRef, useCallback } from 'react';
import { useToggle } from '@livingsecurity/shared';

export const useSidebar = (initialValue) => {
  const timeoutRef = useRef();
  const [isExpanded, toggleSidebar] = useToggle(initialValue);

  const handleSidebarExpanded = useCallback(() => {
    clearTimeout(timeoutRef.current);
    toggleSidebar(true);
  }, [toggleSidebar]);

  const handleSidebarCollapsed = useCallback(() => {
    timeoutRef.current = setTimeout(() => toggleSidebar(false), 100);
  }, [toggleSidebar]);

  return {
    isExpanded,
    handleSidebarExpanded,
    handleSidebarCollapsed,
  };
};
