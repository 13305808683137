import React, { lazy } from 'react';
import { useTranslation } from 'react-i18next';

import { LazyLoad, FeedbackModal, useToggle } from '@livingsecurity/shared';

import { AuthorizedLayout } from 'components';

const UserManagementComponentPkg = lazy(() => import('@livingsecurity/user-management'));

const UserManagement = () => {
  const { t } = useTranslation('shared');
  const [feedbackModalOpen, toggleFeedbackModal] = useToggle(false);

  return (
    <>
      {feedbackModalOpen && (
        <FeedbackModal content={t('messages.downloads.notification.success')} onClose={() => toggleFeedbackModal()} />
      )}
      <AuthorizedLayout withBottomPaddingForHubspot>
        <LazyLoad component={UserManagementComponentPkg} toggleFeedbackModal={toggleFeedbackModal} />
      </AuthorizedLayout>
    </>
  );
};

export default UserManagement;
