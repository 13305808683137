export const PROGRESS_STATE = {
  NOT_AVAILABLE: 'NOT_AVAILABLE',
  AVAILABLE: 'AVAILABLE',
  IN_PROGRESS: 'STARTED',
  COMPLETED: 'COMPLETED',
};

export const EVENT_TYPES = {
  CONTENT_STARTED: 'CONTENT_STARTED',
  ANSWER_SUBMITTED: 'ANSWER_SUBMITTED',
  CONTENT_COMPLETED: 'CONTENT_COMPLETED',
  VIDEO_CHECKPOINT: 'VIDEO_CHECKPOINT',
  ASSIGNMENT_ATTEMPTED: 'ASSIGNMENT_ATTEMPTED',
  ASSIGNMENT_COMPLETED: 'ASSIGNMENT_COMPLETED',
  RETAKE_QUIZ: 'RETAKE_QUIZ',
};

export const SESSION_EVENTS = {
  SESSION_ASSIGNMENT_STARTED: 'SESSION_ASSIGNMENT_STARTED',
  SESSION_CONTENT_STARTED: 'SESSION_CONTENT_STARTED',
  SESSION_ANSWER_SUBMITTED: 'SESSION_ANSWER_SUBMITTED',
  SESSION_CONTENT_COMPLETED: 'SESSION_CONTENT_COMPLETED',
  SESSION_VIDEO_CHECKPOINT: 'SESSION_VIDEO_CHECKPOINT',
  SESSION_ASSIGNMENT_ATTEMPTED: 'SESSION_ASSIGNMENT_ATTEMPTED',
  SESSION_ASSIGNMENT_COMPLETED: 'SESSION_ASSIGNMENT_COMPLETED',
  SESSION_RETAKE_QUIZ: 'SESSION_RETAKE_QUIZ',
};

export const PLATFORM_EVENTS = {
  [SESSION_EVENTS.SESSION_CONTENT_STARTED]: 'CONTENT_STARTED',
  [SESSION_EVENTS.SESSION_ANSWER_SUBMITTED]: 'ANSWER_SUBMITTED',
  [SESSION_EVENTS.SESSION_CONTENT_COMPLETED]: 'CONTENT_COMPLETED',
  [SESSION_EVENTS.SESSION_VIDEO_CHECKPOINT]: 'VIDEO_CHECKPOINT',
  [SESSION_EVENTS.SESSION_ASSIGNMENT_ATTEMPTED]: 'ASSIGNMENT_ATTEMPTED',
  [SESSION_EVENTS.SESSION_ASSIGNMENT_COMPLETED]: 'ASSIGNMENT_COMPLETED',
  [SESSION_EVENTS.SESSION_RETAKE_QUIZ]: 'RETAKE_QUIZ',
};

export const LMS_EVENTS = {
  [SESSION_EVENTS.SESSION_ASSIGNMENT_STARTED]: 'LMS_SESSION_STARTED',
  [SESSION_EVENTS.SESSION_CONTENT_STARTED]: 'LMS_CONTENT_STARTED',
  [SESSION_EVENTS.SESSION_ANSWER_SUBMITTED]: 'LMS_ANSWER_SUBMITTED',
  [SESSION_EVENTS.SESSION_CONTENT_COMPLETED]: 'LMS_CONTENT_COMPLETED',
  [SESSION_EVENTS.SESSION_VIDEO_CHECKPOINT]: 'LMS_VIDEO_CHECKPOINT',
  [SESSION_EVENTS.SESSION_ASSIGNMENT_ATTEMPTED]: 'LMS_ASSIGNMENT_ATTEMPTED',
  [SESSION_EVENTS.SESSION_ASSIGNMENT_COMPLETED]: 'LMS_SESSION_COMPLETED',
};

export const CONTENT_VIEWER_TYPES = {
  MODULE: 'independentModule',
  SERIES: 'series',
};

export const ASSIGNMENT_ENVIRONMENT = {
  LMS: 'LMS',
  PLATFORM: 'PLATFORM',
};
