const CAMPAIGNS_COLLAPSE_LIST = {
  LIST_MAX_HEIGHT: 304,
  LIST_MAX_LENGTH: 4,
  DEFAULT_LIST_MAX_LENGTH: 1500,
  LIST_MAX_RATIO: 40,
};

const RULES_COLLAPSE_LIST = {
  LIST_MAX_HEIGHT: 106,
  LIST_MAX_LENGTH: 1,
  DEFAULT_LIST_MAX_LENGTH: 150,
  LIST_MAX_RATIO: 54,
  LIST_DYNAMIC_RATIO: 22,
  LIST_DYNAMIC_MAX_HEIGHT: 5,
};

export default {
  CAMPAIGNS_COLLAPSE_LIST,
  RULES_COLLAPSE_LIST,
};
