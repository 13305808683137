import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { Navbar, tokenService, useAuth0 } from '@livingsecurity/shared';
import { AuthEntity } from '_entities';
import Sidebar, { APP_TYPE } from '../../blocks/Sidebar';
import * as Styled from './styles';

const { getCompanyInfo } = AuthEntity.selectors;
const { setCompanyInfo } = AuthEntity.actions;

const AuthorizedLayout = ({ children, withoutPadding, withBottomPaddingForHubspot, app }) => {
  const dispatch = useDispatch();
  const [tokenStatus, setTokenStatus] = useState(false);
  const [iframeRoute, setIFrameRoute] = useState();
  const [iframeCompany, setIFrameCompany] = useState();
  const { getIdTokenClaims } = useAuth0();
  const flags = useFlags();

  const companyInfo = useSelector(getCompanyInfo);

  useEffect(() => {
    // TODO: move logic of setting token and userId to separate util
    const getUserToken = async () => {
      const { __raw: token } = await getIdTokenClaims();

      tokenService.manageToken(token);
      setTokenStatus(true);
    };

    getUserToken();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (window.addEventListener) {
      window.addEventListener('message', onMessage, false);
    } else if (window.attachEvent) {
      window.attachEvent('onmessage', onMessage, false);
    }

    function onMessage(event) {
      // Check sender origin to be trusted
      if (
        !event.origin.includes('livingsecurity.com') &&
        !event.origin.includes('lsplatform.dev') &&
        !event.origin.includes('localhost:3001')
      ) {
        return;
      }

      const data = event?.data;
      if (typeof window[data.func] === 'function') {
        window[data.func].call(null, data.message);
      }
    }

    function setCurrentRoute(message) {
      setIFrameRoute(message);
    }

    function setCurrentCompany(message) {
      setIFrameCompany(message);
    }

    window.setCurrentRoute = setCurrentRoute;
    window.setCurrentCompany = setCurrentCompany;
    return () => {
      window.setCurrentRoute = null;
      window.setCurrentCompany = null;
      window.removeEventListener('message', onMessage);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Styled.Struct>
      <Navbar
        companyInfo={companyInfo}
        flags={flags}
        setCompanyInfo={setCompanyInfo}
        iframeCompanyName={iframeCompany}
        dispatch={dispatch}
        height="57px"
      />
      <Styled.Container>
        <Sidebar app={app} tokenStatus={tokenStatus} currentRoute={iframeRoute} />
        <Styled.ContentWrapper>
          <Styled.ContentBlock>
            <Styled.Content withoutPadding={withoutPadding} withBottomPaddingForHubspot={withBottomPaddingForHubspot}>
              {children}
            </Styled.Content>
          </Styled.ContentBlock>
        </Styled.ContentWrapper>
      </Styled.Container>
    </Styled.Struct>
  );
};

AuthorizedLayout.propTypes = {
  app: PropTypes.string,
  withoutPadding: PropTypes.bool,
  withBottomPaddingForHubspot: PropTypes.bool,
};

AuthorizedLayout.defaultProps = {
  app: APP_TYPE.TRAINING,
  withoutPadding: false,
  withBottomPaddingForHubspot: false,
};

export default AuthorizedLayout;
