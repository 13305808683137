import i18n from 'i18next';
import ICU from 'i18next-icu';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import bg from 'i18next-icu/locale-data/bg';
import cs from 'i18next-icu/locale-data/cs';
import de from 'i18next-icu/locale-data/de';
import en from 'i18next-icu/locale-data/en';
import es from 'i18next-icu/locale-data/es';
import fr from 'i18next-icu/locale-data/fr';
import hu from 'i18next-icu/locale-data/hu';
import it from 'i18next-icu/locale-data/it';
import ja from 'i18next-icu/locale-data/ja';
import ko from 'i18next-icu/locale-data/ko';
import nl from 'i18next-icu/locale-data/nl';
import pl from 'i18next-icu/locale-data/pl';
import pt from 'i18next-icu/locale-data/pt';
import ru from 'i18next-icu/locale-data/ru';
import th from 'i18next-icu/locale-data/th';
import tr from 'i18next-icu/locale-data/tr';
import vi from 'i18next-icu/locale-data/vi';
import zh from 'i18next-icu/locale-data/zh';

import * as locales from '../../locales';
import { ruPlayerDashboard } from '../../locales';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .use(
    new ICU({
      localeData: [bg, cs, de, en, es, fr, hu, it, ja, ko, nl, pl, pt, ru, th, tr, vi, zh],
    }),
  )
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'development',
    resources: {
      // German
      de: {
        configuration: { ...locales.deConfiguration },
        contentViewer: { ...locales.deContentViewer },
        playerDashboard: { ...locales.dePlayerDashboard },
        reporting: { ...locales.deReporting },
        messages: { ...locales.deMessages },
        campaignBuilder: { ...locales.deCampaignBuilder },
        catalog: { ...locales.deCatalog },
        audience: { ...locales.deAudience },
        shared: { ...locales.deShared },
        userManagement: { ...locales.deUserManagement },
        companies: { ...locales.deCompanies },
        lsAdmins: { ...locales.deLsAdmins },
        participantDashboard: { ...locales.deParticipantDashboard },
        sidebar: { ...locales.deSidebar },
      },
      // English
      en: {
        configuration: { ...locales.enConfiguration },
        contentViewer: { ...locales.enContentViewer },
        playerDashboard: { ...locales.enPlayerDashboard },
        reporting: { ...locales.enReporting },
        messages: { ...locales.enMessages },
        campaignBuilder: { ...locales.enCampaignBuilder },
        catalog: { ...locales.enCatalog },
        audience: { ...locales.enAudience },
        shared: { ...locales.enShared },
        userManagement: { ...locales.enUserManagement },
        companies: { ...locales.enCompanies },
        lsAdmins: { ...locales.enLsAdmins },
        participantDashboard: { ...locales.enParticipantDashboard },
        sidebar: { ...locales.enSidebar },
      },
      // Spanish (Mexico) - fallback
      es: {
        configuration: { ...locales.esMXConfiguration },
        contentViewer: { ...locales.esMXContentViewer },
        playerDashboard: { ...locales.esMXPlayerDashboard },
        reporting: { ...locales.esMXReporting },
        messages: { ...locales.esMXMessages },
        campaignBuilder: { ...locales.esMXCampaignBuilder },
        catalog: { ...locales.esMXCatalog },
        audience: { ...locales.esMXAudience },
        shared: { ...locales.esMXShared },
        userManagement: { ...locales.esMXUserManagement },
        companies: { ...locales.esMXCompanies },
        lsAdmins: { ...locales.esMXLsAdmins },
        participantDashboard: { ...locales.esMXParticipantDashboard },
        sidebar: { ...locales.esMXSidebar },
      },
      // Spanish (Mexico)
      'es-mx': {
        configuration: { ...locales.esMXConfiguration },
        contentViewer: { ...locales.esMXContentViewer },
        playerDashboard: { ...locales.esMXPlayerDashboard },
        reporting: { ...locales.esMXReporting },
        messages: { ...locales.esMXMessages },
        campaignBuilder: { ...locales.esMXCampaignBuilder },
        catalog: { ...locales.esMXCatalog },
        audience: { ...locales.esMXAudience },
        shared: { ...locales.esMXShared },
        userManagement: { ...locales.esMXUserManagement },
        companies: { ...locales.esMXCompanies },
        lsAdmins: { ...locales.esMXLsAdmins },
        participantDashboard: { ...locales.esMXParticipantDashboard },
        sidebar: { ...locales.esMXSidebar },
      },
      // French
      fr: {
        configuration: { ...locales.frConfiguration },
        contentViewer: { ...locales.frContentViewer },
        playerDashboard: { ...locales.frPlayerDashboard },
        reporting: { ...locales.frReporting },
        messages: { ...locales.frMessages },
        campaignBuilder: { ...locales.frCampaignBuilder },
        catalog: { ...locales.frCatalog },
        audience: { ...locales.frAudience },
        shared: { ...locales.frShared },
        userManagement: { ...locales.frUserManagement },
        companies: { ...locales.frCompanies },
        lsAdmins: { ...locales.frLsAdmins },
        participantDashboard: { ...locales.frParticipantDashboard },
        sidebar: { ...locales.frSidebar },
      },
      // French (Canadian)
      'fr-ca': {
        configuration: { ...locales.frCAConfiguration },
        contentViewer: { ...locales.frCAContentViewer },
        playerDashboard: { ...locales.frCAPlayerDashboard },
        reporting: { ...locales.frCAReporting },
        messages: { ...locales.frCAMessages },
        campaignBuilder: { ...locales.frCACampaignBuilder },
        catalog: { ...locales.frCACatalog },
        audience: { ...locales.frCAAudience },
        shared: { ...locales.frCAShared },
        userManagement: { ...locales.frCAUserManagement },
        companies: { ...locales.frCACompanies },
        lsAdmins: { ...locales.frCALsAdmins },
        participantDashboard: { ...locales.frCAParticipantDashboard },
        sidebar: { ...locales.frCASidebar },
      },
      // Italian
      'it-IT': {
        configuration: { ...locales.itITConfiguration },
        contentViewer: { ...locales.itITContentViewer },
        playerDashboard: { ...locales.itITPlayerDashboard },
        reporting: { ...locales.itBRReporting },
        messages: { ...locales.itITMessages },
        campaignBuilder: { ...locales.itITCampaignBuilder },
        catalog: { ...locales.itITCatalog },
        audience: { ...locales.itITAudience },
        shared: { ...locales.itITShared },
        userManagement: { ...locales.itITUserManagement },
        companies: { ...locales.itITCompanies },
        lsAdmins: { ...locales.itITLsAdmins },
        participantDashboard: { ...locales.itITParticipantDashboard },
        sidebar: { ...locales.itITSidebar },
      },
      // Japanese
      ja: {
        configuration: { ...locales.jaConfiguration },
        contentViewer: { ...locales.jaContentViewer },
        playerDashboard: { ...locales.jaPlayerDashboard },
        reporting: { ...locales.jaReporting },
        messages: { ...locales.jaMessages },
        campaignBuilder: { ...locales.jaCampaignBuilder },
        catalog: { ...locales.jaCatalog },
        audience: { ...locales.jaAudience },
        shared: { ...locales.jaShared },
        userManagement: { ...locales.jaUserManagement },
        companies: { ...locales.jaCompanies },
        lsAdmins: { ...locales.jaLsAdmins },
        participantDashboard: { ...locales.jaParticipantDashboard },
        sidebar: { ...locales.jaSidebar },
      },
      // Dutch
      'nl-NL': {
        configuration: { ...locales.nlNLConfiguration },
        contentViewer: { ...locales.nlNLContentViewer },
        playerDashboard: { ...locales.nlNLPlayerDashboard },
        reporting: { ...locales.nlBRReporting },
        messages: { ...locales.nlNLMessages },
        campaignBuilder: { ...locales.nlNLCampaignBuilder },
        catalog: { ...locales.nlNLCatalog },
        audience: { ...locales.nlNLAudience },
        shared: { ...locales.nlNLShared },
        userManagement: { ...locales.nlNLUserManagement },
        companies: { ...locales.nlNLCompanies },
        lsAdmins: { ...locales.nlNLLsAdmins },
        participantDashboard: { ...locales.nlNLParticipantDashboard },
        sidebar: { ...locales.nlNLSidebar },
      },
      // Polish
      'pl-PL': {
        configuration: { ...locales.plPLConfiguration },
        contentViewer: { ...locales.plPLContentViewer },
        playerDashboard: { ...locales.plPLPlayerDashboard },
        reporting: { ...locales.plBRReporting },
        messages: { ...locales.plPLMessages },
        campaignBuilder: { ...locales.plPLCampaignBuilder },
        catalog: { ...locales.plPLCatalog },
        audience: { ...locales.plPLAudience },
        shared: { ...locales.plPLShared },
        userManagement: { ...locales.plPLUserManagement },
        companies: { ...locales.plPLCompanies },
        lsAdmins: { ...locales.plPLLsAdmins },
        participantDashboard: { ...locales.plPLParticipantDashboard },
        sidebar: { ...locales.plPLSidebar },
      },
      // Portuguese (Brazil) - fallback
      pt: {
        configuration: { ...locales.ptBRConfiguration },
        contentViewer: { ...locales.ptBRContentViewer },
        playerDashboard: { ...locales.ptBRPlayerDashboard },
        reporting: { ...locales.ptBRReporting },
        messages: { ...locales.ptBRMessages },
        campaignBuilder: { ...locales.ptBRCampaignBuilder },
        catalog: { ...locales.ptBRCatalog },
        audience: { ...locales.ptBRAudience },
        shared: { ...locales.ptBRShared },
        userManagement: { ...locales.ptBRUserManagement },
        companies: { ...locales.ptBRCompanies },
        lsAdmins: { ...locales.ptBRLsAdmins },
        participantDashboard: { ...locales.ptBRParticipantDashboard },
        sidebar: { ...locales.ptBRSidebar },
      },
      // Portuguese (Brazil)
      'pt-br': {
        configuration: { ...locales.ptBRConfiguration },
        contentViewer: { ...locales.ptBRContentViewer },
        playerDashboard: { ...locales.ptBRPlayerDashboard },
        reporting: { ...locales.ptBRReporting },
        messages: { ...locales.ptBRMessages },
        campaignBuilder: { ...locales.ptBRCampaignBuilder },
        catalog: { ...locales.ptBRCatalog },
        audience: { ...locales.ptBRAudience },
        shared: { ...locales.ptBRShared },
        userManagement: { ...locales.ptBRUserManagement },
        companies: { ...locales.ptBRCompanies },
        lsAdmins: { ...locales.ptBRLsAdmins },
        participantDashboard: { ...locales.ptBRParticipantDashboard },
        sidebar: { ...locales.ptBRSidebar },
      },
      // Turkish - fallback
      tr: {
        configuration: { ...locales.trConfiguration },
        contentViewer: { ...locales.trContentViewer },
        playerDashboard: { ...locales.trPlayerDashboard },
        reporting: { ...locales.trReporting },
        messages: { ...locales.trMessages },
        campaignBuilder: { ...locales.trCampaignBuilder },
        catalog: { ...locales.trCatalog },
        audience: { ...locales.trAudience },
        shared: { ...locales.trShared },
        userManagement: { ...locales.trUserManagement },
        companies: { ...locales.trCompanies },
        lsAdmins: { ...locales.trLsAdmins },
        participantDashboard: { ...locales.trParticipantDashboard },
        sidebar: { ...locales.trSidebar },
      },
      // Turkish
      'tr-TR': {
        configuration: { ...locales.trConfiguration },
        contentViewer: { ...locales.trContentViewer },
        playerDashboard: { ...locales.trPlayerDashboard },
        reporting: { ...locales.trReporting },
        messages: { ...locales.trMessages },
        campaignBuilder: { ...locales.trCampaignBuilder },
        catalog: { ...locales.trCatalog },
        audience: { ...locales.trAudience },
        shared: { ...locales.trShared },
        userManagement: { ...locales.trUserManagement },
        companies: { ...locales.trCompanies },
        lsAdmins: { ...locales.trLsAdmins },
        participantDashboard: { ...locales.trParticipantDashboard },
        sidebar: { ...locales.trSidebar },
      },
      // Chinese (Simplified) - fallback
      zh: {
        configuration: { ...locales.zhCNConfiguration },
        contentViewer: { ...locales.zhCNContentViewer },
        playerDashboard: { ...locales.zhCNPlayerDashboard },
        reporting: { ...locales.zhCNReporting },
        messages: { ...locales.zhCNMessages },
        campaignBuilder: { ...locales.zhCNCampaignBuilder },
        catalog: { ...locales.zhCNCatalog },
        audience: { ...locales.zhCNAudience },
        shared: { ...locales.zhCNShared },
        userManagement: { ...locales.zhCNUserManagement },
        companies: { ...locales.zhCNCompanies },
        lsAdmins: { ...locales.zhCNLsAdmins },
        participantDashboard: { ...locales.zhCNParticipantDashboard },
        sidebar: { ...locales.zhCNSidebar },
      },
      // Chinese (Simplified)
      'zh-Hans': {
        configuration: { ...locales.zhCNConfiguration },
        contentViewer: { ...locales.zhCNContentViewer },
        playerDashboard: { ...locales.zhCNPlayerDashboard },
        reporting: { ...locales.zhCNReporting },
        messages: { ...locales.zhCNMessages },
        campaignBuilder: { ...locales.zhCNCampaignBuilder },
        catalog: { ...locales.zhCNCatalog },
        audience: { ...locales.zhCNAudience },
        shared: { ...locales.zhCNShared },
        userManagement: { ...locales.zhCNUserManagement },
        companies: { ...locales.zhCNCompanies },
        lsAdmins: { ...locales.zhCNLsAdmins },
        participantDashboard: { ...locales.zhCNParticipantDashboard },
        sidebar: { ...locales.zhCNSidebar },
      },
      // Chinese (Traditional)
      'zh-Hant': {
        configuration: { ...locales.zhHKConfiguration },
        contentViewer: { ...locales.zhHKContentViewer },
        playerDashboard: { ...locales.zhHKPlayerDashboard },
        reporting: { ...locales.zhHKReporting },
        messages: { ...locales.zhHKMessages },
        campaignBuilder: { ...locales.zhHKCampaignBuilder },
        catalog: { ...locales.zhHKCatalog },
        audience: { ...locales.zhHKAudience },
        shared: { ...locales.zhHKShared },
        userManagement: { ...locales.zhHKUserManagement },
        companies: { ...locales.zhHKCompanies },
        lsAdmins: { ...locales.zhHKLsAdmins },
        participantDashboard: { ...locales.zhHKParticipantDashboard },
        sidebar: { ...locales.zhHKSidebar },
      },
      // Chinese (Traditional) - fallback
      'zh-hk': {
        configuration: { ...locales.zhHKConfiguration },
        contentViewer: { ...locales.zhHKContentViewer },
        playerDashboard: { ...locales.zhHKPlayerDashboard },
        reporting: { ...locales.zhHKReporting },
        messages: { ...locales.zhHKMessages },
        campaignBuilder: { ...locales.zhHKCampaignBuilder },
        catalog: { ...locales.zhHKCatalog },
        audience: { ...locales.zhHKAudience },
        shared: { ...locales.zhHKShared },
        userManagement: { ...locales.zhHKUserManagement },
        companies: { ...locales.zhHKCompanies },
        lsAdmins: { ...locales.zhHKLsAdmins },
        participantDashboard: { ...locales.zhHKParticipantDashboard },
        sidebar: { ...locales.zhHKSidebar },
      },
      // Bulgarian
      bg: {
        contentViewer: { ...locales.bgContentViewer },
        playerDashboard: { ...locales.bgPlayerDashboard },
        participantDashboard: { ...locales.bgParticipantDashboard },
        shared: { ...locales.bgShared },
      },
      // Czech
      cs: {
        contentViewer: { ...locales.csContentViewer },
        playerDashboard: { ...locales.csPlayerDashboard },
        participantDashboard: { ...locales.csParticipantDashboard },
        shared: { ...locales.csShared },
      },
      // Hungarian
      hu: {
        contentViewer: { ...locales.csContentViewer },
        playerDashboard: { ...locales.csPlayerDashboard },
        participantDashboard: { ...locales.csParticipantDashboard },
        shared: { ...locales.csShared },
      },
      // Korean
      ko: {
        contentViewer: { ...locales.koContentViewer },
        playerDashboard: { ...locales.koPlayerDashboard },
        participantDashboard: { ...locales.koParticipantDashboard },
        shared: { ...locales.koShared },
      },
      // Vietnamese
      vi: {
        contentViewer: { ...locales.viContentViewer },
        playerDashboard: { ...locales.viPlayerDashboard },
        participantDashboard: { ...locales.viParticipantDashboard },
        shared: { ...locales.viShared },
      },
      // Russian
      ru: {
        contentViewer: { ...locales.ruContentViewer },
        playerDashboard: { ...locales.ruPlayerDashboard },
        participantDashboard: { ...locales.ruParticipantDashboard },
        shared: { ...locales.ruShared },
      },
      // Thai
      'th-TH': {
        contentViewer: { ...locales.thContentViewer },
        playerDashboard: { ...locales.thPlayerDashboard },
        participantDashboard: { ...locales.thParticipantDashboard },
        shared: { ...locales.thShared },
      },
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      prefix: '{{',
      suffix: '}}',
    },
    react: {
      wait: true,
    },
    detection: {
      order: ['querystring', 'localStorage'],
      lookupQuerystring: 'lang',
      lookupLocalStorage: 'i18nextLng',
    },
  });

export default i18n;
