export default {
  QUESTION: 'question',
  ANSWER: 'answer',
  VIDEO: 'video',
  FOLDER: 'folder',
  SERIES: 'series',
  INDEPENDENT_MODULE: 'independentModule',
  EPISODE: 'episode',
  PUZZLE: 'puzzle',
  ASSESSMENT: 'assessment',
  MODULE: 'embeddedModule',
  CLOUDINARY: 'cloudinaryVideo',
  EMAIL_NOTIFICATION: 'emailNotification',
  SLACK_NOTIFICATION: 'slackNotification',
  MS_TEAMS_NOTIFICATION: 'msTeamsNotification',
  CHAT_NOTIFICATION: 'chatNotification',
  CONTENT_IMAGES: 'contentImages',
};
