import { AUTH_TOKEN } from '../../../_constants';

export const manageToken = (token = null) => {
  if (typeof token === 'string' && token.length > 1) {
    saveToken(token);
  }
  // removeToken();
};

export const saveToken = (token) => {
  localStorage.setItem(AUTH_TOKEN, token);
  // store.dispatch(AuthEntity.actions.setToken(token));
};

// export const removeToken = (settings) => {
//   const withRedirect = get(settings, 'withRedirect', true);
//   store.dispatch(AuthEntity.actions.logout(withRedirect));
//   localStorage.removeItem(AUTH_TOKEN);
// };

export const getToken = () => localStorage.getItem(AUTH_TOKEN) || null;

export const startApp = async () => {
  const params = new URLSearchParams(window.location.search);
  const token = params.get('auth_token') || getToken() || null;
  // if (isNull(token)) {
  //   return store.dispatch(AuthEntity.actions.startWithoutToken());
  // }
  manageToken(token);
  // try {
  //   const { token } = await store.dispatch(AuthEntity.actions.updateToken());
  //   manageToken(token);
  //   return await store.dispatch(AuthEntity.actions.getInitialUserData());
  // } catch (error) {
  //   removeToken();
  //   store.dispatch(AuthEntity.actions.startWithoutToken());
  // }
};

export default {
  // removeToken,
  manageToken,
  // saveToken,
  // startApp,
  getToken,
};
