import styled from 'styled-components';

export const StatsSection = styled.div`
  width: 100%;
  padding: 0 8px;
  margin: 20px 0 ${({ isStandalonePlayer }) => (isStandalonePlayer ? '20px' : '70px')};
`;

export const Rank = styled.div`
  margin-top: 18px;
`;

export const Accuracy = styled.div`
  margin-top: 18px;
`;
