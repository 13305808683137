import React, { useMemo } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  Hotspot,
  Vishing,
  Unscramble,
  EmailBuilder,
  Respond,
  DatingGame,
  ReOrder,
  Corkboard,
  Classify,
  Flags,
  Phishing,
  CameraFeed,
  ImageComplete,
} from '@livingsecurity/cyberpuzzles';

// import '@livingsecurity/cyberpuzzles/dist/styles/index.css';
import { PUZZLE_TYPES } from '../../_constants';
import * as Styled from './styles';

const PuzzleComponent = ({ handlePuzzleEnding, puzzleContent, ...props }) => {
  const { t } = useTranslation('contentViewer');
  const puzzleVariation = useMemo(() => {
    if (Array.isArray(puzzleContent?.puzzleVariation) && puzzleContent?.puzzleVariation?.length > 0) {
      return puzzleContent.puzzleVariation[0];
    }

    if (typeof puzzleContent?.puzzleVariation === 'string') {
      return puzzleContent.puzzleVariation;
    }

    // TODO default?
    return 'entranceExam';
  }, [puzzleContent]);

  const renderPuzzle = () => {
    const newProps = {
      puzzleState: {},
      puzzleVariation,
      puzzleContent,
      isEmbedded: true,
      callback: handlePuzzleEnding,
      ...props,
    };
    newProps.x = null;
    newProps.y = null;

    if (!get(puzzleContent, 'puzzleType[0]'))
      return (
        <Styled.PuzzleLoadFailed>
          {t ? t('failed-puzzle-load') : "Couldn't load this puzzle. PuzzleType is missing in Contentful..."}
        </Styled.PuzzleLoadFailed>
      );

    switch (puzzleContent.puzzleType[0]) {
      case PUZZLE_TYPES.HOTSPOT:
        return <Hotspot {...newProps} />;
      case PUZZLE_TYPES.VISHING:
        return <Vishing {...newProps} />;
      case PUZZLE_TYPES.CRAFT_PHISH:
        return <EmailBuilder {...newProps} />;
      case PUZZLE_TYPES.DATING_GAME:
        return <DatingGame {...newProps} />;
      case PUZZLE_TYPES.UNSCRAMBLE:
        return <Unscramble {...newProps} />;
      case PUZZLE_TYPES.REORDER:
        return <ReOrder {...newProps} />;
      case PUZZLE_TYPES.CORKBOARD:
        return <Corkboard {...newProps} />;
      case PUZZLE_TYPES.RESPONSE:
        return <Respond {...newProps} />;
      case PUZZLE_TYPES.CLASSIFY:
        return <Classify {...newProps} />;
      case PUZZLE_TYPES.FLAGS:
        return <Flags {...newProps} />;
      case PUZZLE_TYPES.PHISHING:
        return <Phishing {...newProps} />;
      case PUZZLE_TYPES.CAMERA_FEED:
        return <CameraFeed {...newProps} />;
      case PUZZLE_TYPES.IMAGE_COMPLETE:
        return <ImageComplete {...newProps} />;
      default:
        return undefined;
    }
  };

  return <Styled.PuzzleResponsiveWrapper>{renderPuzzle()}</Styled.PuzzleResponsiveWrapper>;
};

PuzzleComponent.propTypes = {
  handlePuzzleEnding: PropTypes.func.isRequired,
  puzzleContent: PropTypes.shape({
    puzzleType: PropTypes.arrayOf(PropTypes.string),
    puzzleVariation: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  }).isRequired,
};

export default PuzzleComponent;
