import * as TYPES from './ContentViewer.types';

export const initialState = {
  answers: [],
  contentData: null,
  timelineData: null,
  progress: null,
  assignmentData: null,
  leaderboardInfo: null,
  checkpoints: null,
  campaign: null,
  progressLoading: false,
  contentLoading: false,
  error: null,
  contentError: null,
  progressSaving: false,
  nextAssignment: null,
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPES.LOAD_CAMPAIGN:
      return { ...state };

    case TYPES.LOAD_CONTENT_TIMELINE:
      return { ...state, contentLoading: true };

    case TYPES.LOAD_CONTENT_PROGRESS:
    case TYPES.LOAD_LEADERBOARD_INFO:
    case TYPES.FETCH_COMPANY:
      return { ...state, progressLoading: true };

    case TYPES.SAVE_CONTENT_PROGRESS:
      return { ...state, progressSaving: true };

    case TYPES.LOAD_CONTENT_TIMELINE_FAIL:
      return { ...state, contentLoading: false, contentError: action.error };

    case TYPES.LOAD_CONTENT_PROGRESS_FAIL:
      return { ...state, progressLoading: false, contentError: action.error };

    case TYPES.SAVE_CONTENT_PROGRESS_FAIL:
      return { ...state, error: action.error, progressSaving: false };

    case TYPES.LOAD_LEADERBOARD_INFO_FAIL:
    case TYPES.LOAD_CAMPAIGN_FAIL:
    case TYPES.FETCH_COMPANY_FAIL:
      return {
        ...state,
        error: action.error,
      };

    case TYPES.LOAD_LEADERBOARD_INFO_SUCCESS:
      return { ...state, leaderboardInfo: action.result, progressLoading: false };

    case TYPES.LOAD_CAMPAIGN_SUCCESS:
      return { ...state, campaign: action.result && action.result[0] };

    case TYPES.FETCH_COMPANY_SUCCESS:
      return { ...state, company: action.result };

    case TYPES.LOAD_CONTENT_TIMELINE_SUCCESS:
      return { ...state, timelineData: action.result, contentLoading: false };

    case TYPES.SAVE_CONTENT_PROGRESS_SUCCESS:
      return { ...state, checkpoints: action.result, progressSaving: false };

    case TYPES.LOAD_CONTENT_PROGRESS_SUCCESS:
      return { ...state, assignmentData: { ...state.assignmentData, ...action.result }, progressLoading: false };

    case TYPES.LOAD_NEXT_ASSIGNMENT_CONTENT_SUCCESS:
      return { ...state, nextAssignment: { ...action.payload, content: action.result } };

    case TYPES.SET_INITIAL_PROGRESS:
      return { ...state, progress: action.payload };

    case TYPES.UPDATE_PROGRESS:
      return {
        ...state,
        progress: state.progress.map((item) =>
          item.id === action.payload.id ? { ...item, status: action.payload.status } : item,
        ),
      };

    case TYPES.UPDATE_ASSIGNMENT_PROGRESS:
      return {
        ...state,
        assignmentData: {
          ...(state.assignmentData || {}),
          content: action.payload,
        },
      };

    case TYPES.UPDATE_ANSWERS_LIST:
      return { ...state, answers: action.payload };

    case TYPES.CLEAR_STATE:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
