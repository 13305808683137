import React from 'react';
import PropTypes, { number, string } from 'prop-types';

import * as Styled from './styles';

const StatItem = ({ title, value, children, ...rest }) => (
  <Styled.StatContainer {...rest}>
    <div>{title}</div>
    <Styled.Value>{value}</Styled.Value>
    {children}
  </Styled.StatContainer>
);

StatItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([number, string]).isRequired,
};

export default StatItem;
