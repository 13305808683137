import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { TooltipWrapper, POSITION, URLS } from '@livingsecurity/shared';
import { FullScreenIcon, HelpIcon, CloseIcon, ScreenMinIcon } from 'assets/icons';

import { TOOLTIP_ARROW_OPTIONS } from '@livingsecurity/shared/src/common/constants';

import * as Styled from './styles';
import { useStandalonePlayerCheck } from '../../hooks';

const Controls = ({ isExpanded, handleFullscreen, flags }) => {
  const { t } = useTranslation('contentViewer');
  const history = useHistory();
  const { rxdService, isLMS, isStandalonePlayer } = useStandalonePlayerCheck();

  const redirectToDashboard = useCallback(() => {
    if (isStandalonePlayer) return;

    if (isLMS) {
      rxdService.ConcedeControl();
    } else {
      history.replace(URLS.dashboard);
    }
  }, [history, isLMS, isStandalonePlayer, rxdService]);

  return (
    <Styled.ControlsSection>
      {!flags.tmpHideContentViewerHelpIcon && (
        <a
          href="https://livingsecurity.com/support"
          target="_blank"
          rel="noopener noreferrer"
          data-testid="support-link"
        >
          <Styled.Control animated={isExpanded} data-testid="help-icon">
            <HelpIcon />
          </Styled.Control>
        </a>
      )}
      <TooltipWrapper
        content={t ? t('full-screen') : 'Full Screen'}
        option={TOOLTIP_ARROW_OPTIONS.LIGHT}
        position={POSITION.BOTTOM}
      >
        <Styled.Control
          animated={isExpanded}
          shape="square"
          onClick={handleFullscreen.active ? handleFullscreen.exit : handleFullscreen.enter}
          data-testid="screen-size"
        >
          {handleFullscreen.active ? <ScreenMinIcon /> : <FullScreenIcon />}
        </Styled.Control>
      </TooltipWrapper>
      <Styled.Control onClick={redirectToDashboard} animated={isExpanded} data-testid="close-icon">
        <CloseIcon />
      </Styled.Control>
    </Styled.ControlsSection>
  );
};

Controls.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  handleFullscreen: PropTypes.shape({
    active: PropTypes.bool.isRequired,
    exit: PropTypes.func.isRequired,
    enter: PropTypes.func.isRequired,
  }).isRequired,
};

export default Controls;
