import React, { lazy } from 'react';
import { AuthorizedLayout } from 'components';
import { LazyLoad } from '@livingsecurity/shared';

const PackageLSAdminsComponent = lazy(() => import('@livingsecurity/ls-admins'));

const LSAdmins = () => {
  return (
    <AuthorizedLayout withBottomPaddingForHubspot>
      <LazyLoad component={PackageLSAdminsComponent} />
    </AuthorizedLayout>
  );
};

export default LSAdmins;
