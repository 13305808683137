import React from 'react';
import { useSelector } from 'react-redux';
import { ContentViewerEntity } from '../../../../_entities';

import EpisodesTimelineItem from './EpisodesTimelineItem';

const { getEpisodesContent } = ContentViewerEntity.selectors;

const EpisodesTimeline = () => {
  const episodes = useSelector(getEpisodesContent);

  return !!episodes.length && episodes.map((episode) => <EpisodesTimelineItem key={episode.id} episode={episode} />);
};

export default EpisodesTimeline;
