import styled from 'styled-components';
import { ButtonStyles } from '@livingsecurity/shared';

const FONT_SIZE = {
  L: '36px',
  S: '14px',
  XS: '11px',
  XXS: '10px',
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 500px;
  color: var(--white);
`;

export const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  font-size: ${FONT_SIZE.L};
  line-height: 1;
`;

export const Question = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${FONT_SIZE.S};
  margin-bottom: 16px;
`;

export const StarsBlock = styled.div`
  display: flex;
  flex-direction: columns;
  justify-content: center;
  margin-bottom: 20px;
`;

export const StarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 36px;
`;

export const StarWrapper = styled.div`
  width: 36px;
  height: 36px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Control = styled.button`
  position: absolute;
  border: none;
  width: 26px;
  height: 26px;
  transition: all 0.2s;
  cursor: pointer;

  &:active {
    width: 36px;
    height: 36px;
    transition: 0s;
  }
  &:focus {
    outline: none !important;
  }

  & > svg {
    width: 100%;
    height: 100%;
    fill: ${({ isChecked }) => (isChecked ? 'var(--yellow-two)' : 'none')};
  }
`;

export const StarTitle = styled.div`
  margin-top: 10px;
  font-size: ${FONT_SIZE.XXS};
`;

export const Form = styled.form``;

export const Comment = styled.textarea`
  display: flex;
  background-color: var(--white);
  width: 100%;
  height: 98px;
  padding: 11px 8px;
  margin-bottom: 25px;
  border: none;
  border-radius: 7px;
  font-size: ${FONT_SIZE.S};
  color: var(--black-two);
  resize: none;

  ::placeholder {
    font-size: ${FONT_SIZE.XS};
    padding-top: 4px;
  }
`;

export const BottomSection = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  height: 50px;

  ${ButtonStyles.ButtonWrapper} {
    min-width: 224px;
    height: 48px;
    background-color: var(--dodge-blue-two);
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    color: var(--white);

    &:disabled {
      background-color: rgba(45, 152, 212, 0.5);
      pointer-events: auto;
      cursor: not-allowed;
    }
  }
`;

export const SkipTitle = styled.div`
  color: var(--white50);
  font-size: ${FONT_SIZE.S};
  cursor: pointer;

  &:hover {
    color: var(--white);
  }
`;
