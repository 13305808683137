import React, { useState, memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  useAuth0,
  ARROW_ICON,
  useLSTeams,
  getTrainingPermalinkFromRoute,
  URLS,
  TEAMS_URLS,
  USER_GROUPS,
} from '@livingsecurity/shared';
import { SidebarDropdown } from 'components';
import { getRoutes } from './constants';
import * as Styled from './styles';

function TeamsSidebar({ currentRoute }) {
  const { t } = useTranslation(['sidebar', 'shared']);
  const { hasAccess } = useLSTeams();
  const { pathname } = useLocation();
  const { replace } = useHistory();
  const [isOpen, setOpen] = useState(true);
  const toggleOpen = () => setOpen((prev) => !prev);

  const { userGroups, isUserHasRights } = useAuth0();
  const isLsAdmin = isUserHasRights([USER_GROUPS.TEAMS_LS_ADMIN]);

  useEffect(() => {
    if (currentRoute) {
      const formattedRoute = getTrainingPermalinkFromRoute(currentRoute);
      if (isLsAdmin && formattedRoute === TEAMS_URLS.companies && pathname !== TEAMS_URLS.companies) {
        replace(formattedRoute);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRoute]);

  if (!hasAccess) {
    return null;
  }

  return (
    <Styled.Wrapper id="adminSidebar" dark={isLsAdmin} isOpen={isOpen}>
      <Styled.Trigger data-testid="expand sidebar" aria-label="expand sidebar" onClick={toggleOpen}>
        <Styled.TriggerImage src={ARROW_ICON} alt="arrow" isOpen={isOpen} />
      </Styled.Trigger>
      <Styled.ContentWrapper isOpen={isOpen}>
        <Styled.List>
          {getRoutes(t).map((route, id) => {
            const allowedGroups = route.groups.filter((value) => userGroups.includes(value));
            return !!allowedGroups.length && route.nestedRoutes ? (
              <Styled.ListItem key={id}>
                <SidebarDropdown dark={isLsAdmin} isAlwaysOpen userGroups={userGroups} {...route} />
              </Styled.ListItem>
            ) : (
              !!allowedGroups.length && (
                <Styled.ListItem
                  isActiveRoute={matchPath(pathname, { path: route.path })}
                  isOpen
                  withPadding
                  key={id}
                  data-testid={`${route.title}-menu-item`}
                >
                  {route.icon}
                  <Styled.Link to={route.link} aria-label={route.title}>
                    <i className={route.icon} style={{ fontSize: '24px' }} />
                    {route.title}
                  </Styled.Link>
                </Styled.ListItem>
              )
            );
          })}
        </Styled.List>
      </Styled.ContentWrapper>
    </Styled.Wrapper>
  );
}

TeamsSidebar.propTypes = {
  companyInfo: PropTypes.instanceOf(Object),
  currentRoute: PropTypes.string,
};

TeamsSidebar.defaultProps = {
  companyInfo: null,
  currentRoute: null,
};

export default memo(TeamsSidebar);
