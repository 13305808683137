import React, { memo, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ProgressBar } from '@livingsecurity/shared';

import StatItem from '../StatItem';

import { ContentViewerEntity } from '../../_entities';

import * as Styled from './styles';
import { useStandalonePlayerCheck } from '../../hooks';
import { useViewerContext } from '../../utils/context';
import { CONTENT_VIEWER_TYPES } from '../../constants';

const { getHasQuiz, getPuzzlesScore } = ContentViewerEntity.selectors;

const Stats = ({ progress, accuracy, rank, companyGrade, isRequiredGrade, contentType }) => {
  const { flags, isExpanded } = useViewerContext();
  const { rxdService, isLMS, isPlatform } = useStandalonePlayerCheck();
  const hasQuiz = useSelector(getHasQuiz);
  const puzzleScore = useSelector(getPuzzlesScore);
  const isRequiredAccuracyVisible = [flags?.tmpRetakeAssessments, isRequiredGrade, !!companyGrade].every(Boolean);

  const { t } = useTranslation('contentViewer');

  const accuracyTitle = useMemo(() => {
    if (!t) {
      return `${contentType === CONTENT_VIEWER_TYPES.MODULE ? 'Module ' : ''}Accuracy`;
    }
    return contentType === CONTENT_VIEWER_TYPES.MODULE ? t('stats.module-accuracy') : t('stats.accuracy');
  }, [contentType, t]);

  useEffect(() => {
    if (isLMS) {
      if (hasQuiz && Number.isFinite(accuracy)) {
        rxdService.SetScore(accuracy, 100, 0);
        return;
      }
      if (!hasQuiz && Number.isFinite(puzzleScore)) {
        rxdService.SetScore(puzzleScore, 100, 0);
      }
    }
  }, [accuracy, hasQuiz, isLMS, puzzleScore, rxdService]);

  return (
    <Styled.StatsSection isStandalonePlayer={!isPlatform}>
      {!flags?.tmpHideProgress && (
        <StatItem title={t ? t('stats.progress') : 'Progress'} value={`${progress}%`} data-testid="stats-progress">
          <ProgressBar value={progress} animated={isExpanded} />
        </StatItem>
      )}
      {!flags?.tmpHideAccuracy && (
        <Styled.Accuracy>
          <StatItem title={accuracyTitle} value={`${accuracy}%`} data-testid="stats-accuracy">
            <ProgressBar value={accuracy} animated={isExpanded} />
          </StatItem>
        </Styled.Accuracy>
      )}
      {isPlatform && isRequiredAccuracyVisible && hasQuiz && !flags?.tmpHideAccuracy && (
        <Styled.Accuracy>
          <StatItem
            title={t ? t('stats.required-accuracy') : 'Required Accuracy'}
            value={`${companyGrade}%`}
            data-testid="stats-required-accuracy"
          />
        </Styled.Accuracy>
      )}
      {isPlatform ? (
        <Styled.Rank>
          <StatItem
            title={t ? t('stats.rank') : 'Rank'}
            value={rank ?? `${t ? t('not-applicable') : 'N/A'}`}
            data-testid="stats-rank"
          />
        </Styled.Rank>
      ) : (
        <br />
      )}
    </Styled.StatsSection>
  );
};

Stats.propTypes = {
  progress: PropTypes.number.isRequired,
  accuracy: PropTypes.number.isRequired,
  rank: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  companyGrade: PropTypes.number,
  isRequiredGrade: PropTypes.bool.isRequired,
  contentType: PropTypes.oneOf(Object.values(CONTENT_VIEWER_TYPES)).isRequired,
};

Stats.defaultProps = {
  rank: null,
  companyGrade: 0,
};

export default memo(Stats);
