import { useRef, useState, useCallback } from 'react';

export const INFINITE_TIMEOUT = 2147483647; // roughly 24.8 days

export const useFakeLoading = (delay = 1000) => {
  const [fakeLoading, setFakeLoading] = useState(false);
  const timerRef = useRef(null);

  const resetLoading = () => {
    clearTimeout(timerRef?.current);
    setFakeLoading(false);
  };

  const handleLoading = useCallback(
    ({ infinite = false } = {}) => {
      if (timerRef?.current) clearTimeout(timerRef?.current);
      setFakeLoading(true);
      return new Promise((resolve) => {
        timerRef.current = setTimeout(
          () => {
            setFakeLoading(false);
            resolve();
          },
          infinite ? INFINITE_TIMEOUT : delay,
        );
      });
    },
    [delay],
  );

  return {
    fakeLoading,
    timerRef: timerRef?.current,
    startFakeLoading: handleLoading,
    resetLoading,
  };
};
