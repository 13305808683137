import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { URLS } from '@livingsecurity/shared';

import { useCountdownTimer } from '../../../../hooks';

import * as Styled from './styles';

const CongratsScreen = ({ currentAssignmentTitle, nextAssignment, onNext }) => {
  const { t } = useTranslation('contentViewer');
  const history = useHistory();

  const redirectToDashboard = useCallback(() => history.replace(URLS.dashboard), [history]);

  const { counter, cancel } = useCountdownTimer(30, redirectToDashboard);

  const contentTitle = nextAssignment?.content?.fields?.title;
  const nextAssignmentId = nextAssignment?.id;

  const handleNext = useCallback(() => {
    cancel();
    return onNext(nextAssignmentId);
  }, [cancel, nextAssignmentId, onNext]);

  return (
    <Styled.Container>
      <Styled.SuccessIcon width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M32 5.333C17.28 5.333 5.333 17.28 5.333 32 5.333 46.72 17.28 58.667 32 58.667c14.72 0 26.666-11.947 26.666-26.667C58.666 17.28 46.72 5.333 32 5.333Zm0 48c-11.76 0-21.334-9.573-21.334-21.333 0-11.76 9.574-21.333 21.334-21.333S53.333 20.24 53.333 32c0 11.76-9.573 21.333-21.333 21.333Zm12.24-33.12L26.666 37.787l-6.906-6.88-3.76 3.76 10.666 10.666L48 24l-3.76-3.787Z"
          fill="#5BE5BE"
        />
      </Styled.SuccessIcon>
      <Styled.Title>
        <span>{contentTitle ? `${t('congrats-screen.done')}` : t('congrats-screen.all-done')}</span>
        <span>{!!contentTitle && currentAssignmentTitle}</span>
      </Styled.Title>
      <Styled.SubTitle>{t('congrats-screen.redirect')}</Styled.SubTitle>
      <Styled.Timer>{counter}</Styled.Timer>
      <Styled.Actions hasNext={!!contentTitle}>
        <Styled.DashboardLink to={URLS.dashboard}>{t('congrats-screen.return')}</Styled.DashboardLink>
        {!!contentTitle && (
          <Styled.NextAssignment type="button" onClick={handleNext}>
            <Styled.NextTraining>{t('congrats-screen.next-training')}</Styled.NextTraining>
            <Styled.ContentTitle>{contentTitle}</Styled.ContentTitle>
            <Styled.Arrow width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8-8-8Z" fill="currentColor" />
            </Styled.Arrow>
          </Styled.NextAssignment>
        )}
      </Styled.Actions>
    </Styled.Container>
  );
};

CongratsScreen.propTypes = {
  nextAssignment: PropTypes.object,
  onNext: PropTypes.func,
  currentAssignmentTitle: PropTypes.string.isRequired,
};

CongratsScreen.defaultProps = {
  nextAssignment: null,
  onNext: () => {},
};

export default CongratsScreen;
