import React, { lazy } from 'react';
import { AuthorizedLayout } from 'components';
import { LazyLoad } from '@livingsecurity/shared';

const CompanyManagementPkg = lazy(() => import('@livingsecurity/company-management'));

const Companies = () => {
  return (
    <AuthorizedLayout withBottomPaddingForHubspot>
      <LazyLoad component={CompanyManagementPkg} />
    </AuthorizedLayout>
  );
};

export default Companies;
