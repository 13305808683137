import moment from 'moment';

export const formatTime = (ms) => {
  const seconds = ms / 1000;

  const mins = Math.floor(seconds / 60)
    .toString()
    .padStart(2, '0');
  const secs = (seconds % 60).toString().padStart(2, '0');
  return `${mins}:${secs}`;
};

export const isPastDue = (dueDate) => {
  return moment().isAfter(dueDate);
};
