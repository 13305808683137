import React, { lazy } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { useFlags, LDProvider } from 'launchdarkly-react-client-sdk';
import { I18nextProvider } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { isEmpty } from 'lodash';

import {
  LazyLoad,
  useAuth0,
  URLS,
  TEAMS_URLS,
  USER_GROUPS,
  Loader,
  i18n,
  ErrorBoundary,
  GlobalStyleWithLocation,
  ModalProvider,
} from '@livingsecurity/shared';
import StandalonePlayer from '@livingsecurity/content-viewer';
import { PrivateRoute, Home, Actions, LoginLayout } from 'components';

import Audience from 'containers/Audience';
import CampaignBuilder from 'containers/CampaignBuilder';
import Catalog from 'containers/Catalog';
import Analytics from 'containers/Analytics';
import UserManagement from 'containers/UserManagement';
import Companies from 'containers/Companies';
import LSAdmins from 'containers/LSAdmins';
import Config from 'containers/Config';
import { TeamsManagement, TeamsParticipants } from 'containers/TeamsManagement';
import UnifyDashboard from 'containers/UnifyDashboard';
import PhishingDashboard from 'containers/PhishingDashboard';
import { store, history } from './lib';

import 'react-dates/initialize';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import '@livingsecurity/shared/dist/assets/styles/react-toastify.scss';
import '@livingsecurity/shared/dist/assets/styles/react-datepicker.scss';
import '@livingsecurity/shared/dist/assets/styles/react-table.scss';
import '@livingsecurity/cyberpuzzles/dist/styles/index.css';
import '@livingsecurity/cyberblocks/dist/styles/app.css';
import '@livingsecurity/reporting/dist/assets/styles/index.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'cloudinary-video-player/dist/cld-video-player.css';

const ContentViewer = lazy(() => import('containers/ContentViewer'));
const PlayerDashboard = lazy(() => import('containers/PlayerDashboard'));
const ParticipantDashboard = lazy(() => import('containers/ParticipantDashboard'));
const Profile = lazy(() => import('containers/Profile'));

const LazyContentViewer = (props) => <LazyLoad component={ContentViewer} {...props} />;
const LazyPlayerDashboard = (props) => <LazyLoad component={PlayerDashboard} {...props} />;
const LazyParticipantDashboard = (props) => <LazyLoad component={ParticipantDashboard} {...props} />;
const LazyProfile = (props) => <LazyLoad component={Profile} {...props} />;

const LDConsumer = ({ children }) => {
  const flags = useFlags();
  return children({ flags });
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60,
      refetchOnWindowFocus: false,
      retry: false, // after getting error disable auto-retry
    },
  },
});

function App() {
  const { loading, isAuthenticated, user, userGroups } = useAuth0();
  const adminRoles = [
    USER_GROUPS.LS_ADMIN,
    USER_GROUPS.CUSTOMER_ADMIN,
    USER_GROUPS.TRAINING_DEMO,
    USER_GROUPS.TEAMS_COLLABORATOR,
    USER_GROUPS.TEAMS_CUSTOMER_ADMIN,
    USER_GROUPS.TEAMS_LS_ADMIN,
    USER_GROUPS.UNIFY_CUSTOMER_ADMIN,
    USER_GROUPS.PHISHING_CUSTOMER_ADMIN,
    USER_GROUPS.PHISHING_LS_ADMIN,
  ];

  if (loading) {
    return <Loader coverElement />;
  }

  const ldUser =
    isAuthenticated && !isEmpty(user)
      ? {
          key: user.sub,
          email: user.email,
          custom: {
            tenantId: user.tenant_id,
            userGroups,
            createdAt: user.created_at,
            updatedAt: user.updated_at,
            loginCount: user.logins_count,
            blocked: user.blocked,
            metadata: user.user_metadata,
          },
        }
      : undefined;

  return (
    <ModalProvider>
      <LDProvider clientSideID={process.env.REACT_APP_LAUNCH_DARKLY_KEY} user={ldUser} deferInitialization>
        <Provider store={store}>
          <I18nextProvider i18n={i18n}>
            <QueryClientProvider client={queryClient}>
              <div className="App" id="App">
                <ErrorBoundary>
                  <Router history={history}>
                    <GlobalStyleWithLocation />
                    <LDConsumer>
                      {({ flags: { tmpHideLegacyParticipantDashboard } }) => (
                        <Switch>
                          <Route path="/" component={Home} exact />
                          <Route path={URLS.actions} component={Actions} exact />
                          <Route path={URLS.login} component={LoginLayout} />
                          <Route
                            exact
                            path={URLS.player}
                            render={(routeProps) => <StandalonePlayer {...routeProps} />}
                          />
                          <PrivateRoute
                            exact
                            path={URLS.dashboard}
                            component={
                              tmpHideLegacyParticipantDashboard ? LazyParticipantDashboard : LazyPlayerDashboard
                            }
                          />
                          <PrivateRoute
                            path={URLS.assignment}
                            groups={[USER_GROUPS.LS_ADMIN, USER_GROUPS.CUSTOMER_ADMIN, USER_GROUPS.PARTICIPANT]}
                            component={LazyContentViewer}
                          />
                          <PrivateRoute
                            path={URLS.userManagement}
                            groups={[USER_GROUPS.CUSTOMER_ADMIN]}
                            component={UserManagement}
                          />
                          <PrivateRoute
                            path={[
                              URLS.configuration,
                              URLS.config,
                              URLS.policyAcceptance,
                              URLS.account,
                              URLS.userProvisioning,
                              URLS.userDomains,
                              URLS.configCampaigns,
                              URLS.userPolicy,
                              URLS.customNotifications,
                            ]}
                            groups={[USER_GROUPS.CUSTOMER_ADMIN]}
                            component={Config}
                          />

                          <PrivateRoute
                            path={URLS.catalog}
                            groups={[USER_GROUPS.LS_ADMIN, USER_GROUPS.CUSTOMER_ADMIN, USER_GROUPS.TRAINING_DEMO]}
                            component={Catalog}
                          />
                          <PrivateRoute
                            path={URLS.campaignBuilder}
                            groups={[USER_GROUPS.LS_ADMIN, USER_GROUPS.CUSTOMER_ADMIN]}
                            component={CampaignBuilder}
                          />
                          <PrivateRoute
                            path={URLS.audiences}
                            groups={[USER_GROUPS.LS_ADMIN, USER_GROUPS.CUSTOMER_ADMIN]}
                            component={Audience}
                          />
                          <PrivateRoute
                            path={[
                              URLS.campaignDetails,
                              URLS.risk,
                              URLS.training,
                              URLS.participants,
                              URLS.campaigns,
                              URLS.questions,
                            ]}
                            groups={[USER_GROUPS.LS_ADMIN, USER_GROUPS.CUSTOMER_ADMIN]}
                            component={Analytics}
                          />
                          <PrivateRoute path={URLS.profile} component={LazyProfile} />
                          <PrivateRoute path={URLS.companies} groups={[USER_GROUPS.LS_ADMIN]} component={Companies} />
                          <PrivateRoute path={URLS.lsAdmins} groups={[USER_GROUPS.LS_ADMIN]} component={LSAdmins} />
                          <Redirect exact from={URLS.configuration} to={URLS.account} />

                          {/* UNIFY */}
                          <PrivateRoute path={URLS.unify} groups={adminRoles} component={UnifyDashboard} />

                          {/* TEAMS MANAGEMENT */}
                          <Route
                            path={[
                              URLS.teamsBooking,
                              URLS.teamsCancelBooking,
                              URLS.teamsEditBooking,
                              URLS.teamsBookingResult,
                              URLS.teamsBookingCancel,
                              URLS.teamsLeaderboard,
                              TEAMS_URLS.teamsBooking,
                              TEAMS_URLS.teamsCancelBooking,
                              TEAMS_URLS.teamsEditBooking,
                              TEAMS_URLS.teamsBookingResult,
                              TEAMS_URLS.teamsBookingCancel,
                              TEAMS_URLS.teamsLeaderboard,
                            ]}
                            component={TeamsParticipants}
                          />
                          <PrivateRoute path={URLS.teamsBase} groups={adminRoles} component={TeamsManagement} />

                          {/* PHISHING */}
                          <PrivateRoute path={URLS.phishing} groups={adminRoles} component={PhishingDashboard} />

                          <Redirect to="/" />
                        </Switch>
                      )}
                    </LDConsumer>
                  </Router>
                </ErrorBoundary>
              </div>
              <ToastContainer newestOnTop limit={5} />
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </I18nextProvider>
        </Provider>
      </LDProvider>
    </ModalProvider>
  );
}

export default App;
