import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from '@livingsecurity/shared';

import StarsBlock from './StarsBlock';

import * as Styled from './styles';

const Feedback = ({ onSubmit, onSkip, count, loading, flags }) => {
  const { t } = useTranslation('contentViewer');

  const [stars, setStars] = useState(0);
  const [comment, setComment] = useState('');

  const handleComment = useCallback(({ target: { value } }) => setComment(value), []);
  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      return onSubmit({ stars, comment });
    },
    [comment, onSubmit, stars],
  );

  return (
    <Styled.Wrapper>
      <Styled.Title>{t ? t('feedback.title') : 'Your opinion matters'}</Styled.Title>
      <Styled.Question>{t ? t('feedback.question') : 'How effective was this security training?'}</Styled.Question>
      <StarsBlock count={count} stars={stars} onChange={setStars} />
      <Styled.Form onSubmit={handleSubmit}>
        <Styled.Comment
          value={comment}
          onChange={handleComment}
          placeholder={t ? t('feedback.comment-placeholder') : 'Comment on your training mission...'}
        />
        <Styled.BottomSection>
          <Styled.SkipTitle onClick={onSkip}>
            {flags.redirectToCongratsScreen ? t('feedback.skip-continue-link-text') : t('feedback.skip-link-text')}
          </Styled.SkipTitle>
          <Button
            text={
              loading
                ? ''
                : flags.redirectToCongratsScreen
                ? t('feedback.submit-continue-button-text')
                : t('feedback.submit-button-text')
            }
            loading={loading}
            disabled={!stars && !comment}
          />
        </Styled.BottomSection>
      </Styled.Form>
    </Styled.Wrapper>
  );
};

Feedback.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onSkip: PropTypes.func.isRequired,
  count: PropTypes.number,
  loading: PropTypes.bool,
  flags: PropTypes.object.isRequired,
};

Feedback.defaultProps = {
  count: 5,
  loading: false,
};

export default Feedback;
