/* eslint-disable no-unused-expressions */
import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { HubspotChat, Loader, LOCATION_KEY, setToLS, useAuth0, useInitializationService } from '@livingsecurity/shared';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { AuthEntity } from '_entities';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { datadogLogs } from '@datadog/browser-logs';

const { getCompanyInfo } = AuthEntity.selectors;

const PrivateRoute = ({ component: Component, path, groups, ...rest }) => {
  const { loading, isAuthenticated, loginWithRedirect, isUserHasRights, user, userGroups, locale, isImpersonate } =
    useAuth0();
  const companyInfo = useSelector(getCompanyInfo);
  const trainingIgnoreRoutes = useMemo(
    () => ['/phishing', '/phishing/demo', '/dashboard', '/player', '/companies', '/ls-admins'],
    [],
  );
  const { showHubspotWidget } = useInitializationService({ companyInfo });
  const { pathname } = useLocation();
  const hubspotWidget = window.HubSpotConversations?.widget;
  const flags = useFlags();

  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }
    const fn = async () => {
      await loginWithRedirect({
        appState: { targetUrl: pathname },
      });
    };
    fn();
  }, [loading, isAuthenticated, loginWithRedirect, pathname]);

  useEffect(() => {
    if (!showHubspotWidget) {
      hubspotWidget?.remove();
    }
  }, [showHubspotWidget, hubspotWidget]);

  useEffect(() => {
    if (pathname.includes('/teams') && !pathname.includes('/demo')) {
      setToLS(LOCATION_KEY.TEAMS_PATH, pathname);
    } else if (pathname.includes('/unify') && !pathname.includes('/demo')) {
      setToLS(LOCATION_KEY.UNIFY_PATH, pathname);
    } else if (
      !trainingIgnoreRoutes.includes(pathname) &&
      !pathname.includes('/assignment') &&
      !pathname.includes('/demo')
    ) {
      setToLS(LOCATION_KEY.TRAINING_PATH, pathname);
    }
  }, [pathname, trainingIgnoreRoutes]);

  useEffect(() => {
    if (user && isAuthenticated) {
      datadogLogs.onReady(() => {
        datadogLogs.addLoggerGlobalContext('companyId', user.tenant_id);
        datadogLogs.addLoggerGlobalContext('company', companyInfo?.name);
        datadogLogs.addLoggerGlobalContext('usr.email', user.email);
        datadogLogs.addLoggerGlobalContext('usr.id', user.sub);
        datadogLogs.addLoggerGlobalContext('usr.name', user.nickname || user.email);
        datadogLogs.addLoggerGlobalContext('userGroups', userGroups);
        datadogLogs.addLoggerGlobalContext('locale', locale);
        datadogLogs.addLoggerGlobalContext('isImpersonate', isImpersonate);
      });
    }
  }, [isAuthenticated, user, companyInfo, isImpersonate, locale, userGroups]);

  const render = (props) => (
    <>
      <HubspotChat />
      <Component user={user || {}} flags={flags || {}} {...props} />{' '}
    </>
  );

  const isValidUser = isAuthenticated === true && isUserHasRights(groups);

  if (isValidUser && isEmpty(flags)) {
    return <Loader coverElement />;
  }

  return isValidUser ? <Route path={path} render={render} {...rest} /> : <Redirect to="/" />;
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
  groups: PropTypes.arrayOf(PropTypes.string),
};

PrivateRoute.defaultProps = {
  groups: [],
};

export default PrivateRoute;
