import styled, { keyframes, css } from 'styled-components';
import { ButtonStyles } from '@livingsecurity/shared';

export const NotificationContainer = styled.div`
  background-color: #fff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 24px;
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  width: 100%;

  ${ButtonStyles.ButtonWrapper} {
    font-size: 24px;
    font-weight: bold;
    width: 235px;
    height: 65px;
    text-transform: uppercase;
    background-color: var(--dodge-blue-two);
  }
`;

export const NotificationInfo = styled.div`
  color: #000000;
  margin-right: 32px;
`;

export const NotificationNextText = styled.div`
  font-weight: bold;
  font-size: 24px;
`;

export const NotificationNextContent = styled.div`
  font-size: 24px;
`;
export const NotificationNextContentDuration = styled.div`
  font-size: 14px;
`;
