import { isEmpty, isPlainObject } from 'lodash';
import { notification } from '../services';

// eslint-disable-next-line consistent-return
export const showErrorsFromContentful = (error, notificationProps = {}) => {
  if (!error || isEmpty(error)) {
    return notification.error('Error! Try again!');
  }

  if (isPlainObject(error)) {
    return notification.error(error.message, notificationProps);
  }
};
