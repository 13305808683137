import { useState, useEffect, useCallback, useRef } from 'react';

export const useCountdownTimer = (seconds = 10, cb = () => {}) => {
  const [counter, setCounter] = useState(seconds);
  const safeCb = useRef(cb);
  const timer = useRef(0);

  const cancel = useCallback(() => clearTimeout(timer.current), []);

  const resume = useCallback(() => counter > 0 && setCounter(counter - 1), [counter]);

  const reset = useCallback(() => setCounter(seconds), [seconds]);

  useEffect(() => {
    if (counter === 0) {
      clearTimeout(timer.current);
      return safeCb.current();
    }
    timer.current = setTimeout(() => setCounter((c) => c - 1), 1000);

    return () => {
      cancel();
    };
  }, [cancel, counter]);

  return { counter, cancel, resume, reset };
};
