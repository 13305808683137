import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { TimelineItem, TimelineDot, TimelineContent } from 'components/Timeline';

import { getIsActivityCompleted } from '../../../../utils';
import { useViewerContext } from '../../../../utils/context';
import { useStandalonePlayerCheck } from '../../../../hooks/useQueryParams';

import * as Styled from '../../styles';
import { ContentViewerEntity } from '../../../../_entities';
import { PROGRESS_STATE } from '../../../../constants';

const { getProgressMap } = ContentViewerEntity.selectors;

const EpisodeTimelineContent = ({ episode }) => {
  const { t } = useTranslation('contentViewer');
  const { isExpanded, activeModule, handleSetActiveModule, showFeedBack } = useViewerContext();
  const progressMap = useSelector(getProgressMap);

  const {
    id: episodeId,
    content: { startVideo, modules, endVideo },
  } = episode;

  const { isLMS } = useStandalonePlayerCheck();
  const createModuleKey = useCallback((moduleId) => `${episodeId}_${moduleId}`, [episodeId]);

  const getIsContentCompleted = useCallback(
    (id) => progressMap[id]?.status === PROGRESS_STATE.COMPLETED,
    [progressMap],
  );

  const handleModuleClick = useCallback(
    ({ id, type }) =>
      () => {
        const moduleId = createModuleKey(id);
        const disableNavForLMS = isLMS && getIsContentCompleted(moduleId);

        if (moduleId === activeModule || showFeedBack || disableNavForLMS) return;
        handleSetActiveModule(moduleId, type);
      },
    [activeModule, createModuleKey, getIsContentCompleted, handleSetActiveModule, isLMS, showFeedBack],
  );

  return (
    <>
      {startVideo && (
        <TimelineItem
          key={createModuleKey(startVideo.id)}
          completed={getIsActivityCompleted(progressMap[createModuleKey(startVideo.id)] || {})}
          active={createModuleKey(startVideo.id) === activeModule}
          onClick={handleModuleClick(startVideo)}
        >
          <TimelineDot />
          <TimelineContent animated={isExpanded}>
            <Styled.Duration>{`${startVideo.content?.duration || (t ? t('not-applicable') : 'N/A')} ${
              t ? t('minutes-abbreviation') : 'min'
            }`}</Styled.Duration>
            <Styled.AssetText>{startVideo.content?.title || (t ? t('not-applicable') : 'N/A')}</Styled.AssetText>
          </TimelineContent>
        </TimelineItem>
      )}

      {modules.map((module) => (
        <TimelineItem
          key={module.id}
          completed={getIsActivityCompleted(progressMap[createModuleKey(module.id)] || {})}
          active={createModuleKey(module.id) === activeModule}
          onClick={handleModuleClick(module)}
        >
          <TimelineDot />
          <TimelineContent animated={isExpanded}>
            <Styled.Duration>{module.content?.duration && `${module.content?.duration} min`}</Styled.Duration>
            <Styled.AssetText>{module.content?.title || 'N/A'}</Styled.AssetText>
          </TimelineContent>
        </TimelineItem>
      ))}
      {endVideo && (
        <TimelineItem
          key={createModuleKey(endVideo.id)}
          completed={getIsActivityCompleted(progressMap[createModuleKey(endVideo.id)] || {})}
          active={createModuleKey(endVideo.id) === activeModule}
          onClick={handleModuleClick(endVideo)}
        >
          <TimelineDot />
          <TimelineContent animated={isExpanded}>
            <Styled.Duration>{`${endVideo.content?.duration || 'N/A'} min`}</Styled.Duration>
            <Styled.AssetText>{endVideo.content?.title || 'N/A'}</Styled.AssetText>
          </TimelineContent>
        </TimelineItem>
      )}
    </>
  );
};

EpisodeTimelineContent.propTypes = {
  episode: PropTypes.shape({
    id: PropTypes.string.isRequired,
    content: PropTypes.shape({
      startVideo: PropTypes.shape({}),
      modules: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
      endVideo: PropTypes.shape({}),
    }),
  }).isRequired,
  progressMap: PropTypes.shape({}),
};

export default EpisodeTimelineContent;
