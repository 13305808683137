/* istanbul ignore file */
import { useQuery } from 'react-query';
import { merge } from 'lodash';

import { useAuth0, httpClient } from '../../lib';

const loadTemplates = (params) => httpClient.get('/notification/participant_notifications', { params });
const loadTemplateById = (id) => httpClient.get(`/notification/participant_notifications/${id}`);

const QUERY_KEY = 'shared/participant_notifications';

export const useTemplatesQuery = (params, queryOptions) => {
  const {
    user: { tenant_id: tenantId },
  } = useAuth0();

  return useQuery(
    [QUERY_KEY, params],
    () => loadTemplates({ tenant_id: tenantId, ...params }),
    // TODO add { keepPreviousData : true } when pagination will be available. Reference: https://react-query.tanstack.com/guides/paginated-queries
    merge({}, queryOptions),
  );
};

export const useTemplateByIdQuery = (templateId, queryOptions) =>
  useQuery([QUERY_KEY, templateId], () => loadTemplateById(templateId), queryOptions);
