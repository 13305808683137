import styled from 'styled-components';
import { Link as DefaultLink } from 'react-router-dom';

export const StyledAdminNav = styled.div`
  padding: 5px 10px;
  text-transform: uppercase;
  background: #111111;
`;

export const Link = styled(DefaultLink)`
  cursor: pointer;
  font-weight: 400;
  font-size: 0.75rem;
  color: white;

  &:hover {
    color: #4eb3d4;
  }
`;
