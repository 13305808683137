import URLS from './URLS';

// A function to get the associated Teams Management URL
export const getTeamsManagementUrl = () => {
  const currentUrl = window.location.href;

  if (process.env.REACT_APP_TEAMS_MANAGEMENT_URL && process.env.REACT_APP_TEAMS_MANAGEMENT_API) {
    return {
      view: process.env.REACT_APP_TEAMS_MANAGEMENT_URL,
      api: process.env.REACT_APP_TEAMS_MANAGEMENT_API,
    };
  } else if (currentUrl.includes('app.livingsecurity.com')) {
    // PRODUCTION
    return {
      view: 'https://teams-embed.livingsecurity.com',
      api: 'https://teams-api.livingsecurity.com',
    };
  } else if (currentUrl.includes('staging.lsplatform.dev')) {
    // STAGING
    return {
      view: 'https://mfgtm-staging.lsplatform.dev',
      api: 'https://mfgtm-api-staging.livingsecurity.com',
    };
  } else {
    // DEV
    return {
      view: 'https://mfgtm-dev-embed.livingsecurity.com',
      api: 'https://mfgtm-api-dev.livingsecurity.com',
    };
  }
};

// These routes map to the Teams Management views
export const getTeamsPermalinkFromRoute = (route, lsAdmin, isImpersonating) => {
  if (lsAdmin && !isImpersonating && ![TEAMS_URLS.storylines, TEAMS_URLS.lsAdmins].includes(route)) {
    return '/admin/companies';
  }

  switch (route) {
    case TEAMS_URLS.teamsLeaderboard:
      return URLS.teamsLeaderboard;
    case TEAMS_URLS.teamsBooking:
      return URLS.teamsBooking;
    case TEAMS_URLS.teamsCancelBooking:
      return URLS.teamsCancelBooking;
    case TEAMS_URLS.teamsEditBooking:
      return URLS.teamsEditBooking;
    case TEAMS_URLS.teamsBookingResult:
      return URLS.teamsBookingResult;
    case TEAMS_URLS.teamsBookingCancel:
      return URLS.teamsBookingCancel;
    case TEAMS_URLS.leaderboard:
      return '/companyAdmin/leaderboard';
    case TEAMS_URLS.reports:
      return '/companyAdmin/reports';
    case TEAMS_URLS.account:
      return '/companyAdmin/company';
    case TEAMS_URLS.users:
      return '/companyAdmin/users';
    case TEAMS_URLS.companies:
      return '/admin/companies';
    case TEAMS_URLS.lsAdmins:
      return '/admin/settings';
    case TEAMS_URLS.storylines:
      return '/admin/storylines';
    case TEAMS_URLS.scheduling:
    default:
      return '/companyAdmin/scheduling';
  }
};
// These routes map to the Teams Management views
export const getTrainingPermalinkFromRoute = (route = '') => {
  if (route.includes('/companyAdmin/scheduling')) {
    return TEAMS_URLS.scheduling;
  } else if (route.includes('/companyAdmin/leaderboard')) {
    return TEAMS_URLS.leaderboard;
  } else if (route.includes('/companyAdmin/reports')) {
    return TEAMS_URLS.reports;
  } else if (route.includes('/companyAdmin/company')) {
    return TEAMS_URLS.account;
  } else if (route.includes('/companyAdmin/users')) {
    return TEAMS_URLS.users;
  } else if (route.includes('/admin/companies')) {
    return TEAMS_URLS.companies;
  } else if (route.includes('/admin/settings')) {
    return TEAMS_URLS.lsAdmins;
  } else if (route.includes('/admin/storylines')) {
    return TEAMS_URLS.storylines;
  } else {
    return TEAMS_URLS.scheduling;
  }
};

// These are the permalinks seen within the Platform view
export const TEAMS_URLS = {
  // Teams Admin Routes
  demo: '/teams/demo',
  scheduling: '/teams/schedule',
  leaderboard: '/teams/leaderboard',
  reports: '/teams/reports',
  account: '/teams/account',
  users: '/teams/users',
  companies: '/teams/companies',
  lsAdmins: '/teams/ls-admins',
  storylines: '/teams/storylines',

  // Teams Participant Routes
  teamsBooking: '/teams/accept-invite',
  teamsCancelBooking: '/teams/cancel-booking',
  teamsEditBooking: '/teams/edit-booking',
  teamsBookingResult: '/teams/booking-result/:result/:id?',
  teamsBookingCancel: '/teams/booking-result/canceled',

  // Teams Public Routes
  teamsLeaderboard: '/teams/ranking/:id',
};

export const UNIFY_URLS = {
  demo: '/unify/demo',
};

export const PHISH_URLS = {
  demo: '/phishing/demo',
};

// A function to get the associated Unify URL
export const getUnifyUrl = () => {
  const currentUrl = window.location.href;

  if (process.env.REACT_APP_UNIFY_URL) {
    return process.env.REACT_APP_UNIFY_URL;
  } else if (currentUrl.includes('app.livingsecurity.com')) {
    // PRODUCTION
    return 'https://unify.livingsecurity.com';
  } else {
    // STAGING
    // DEV
    return 'https://dev-unify.livingsecurity.com';
  }
};

// A function to get the associated Keepnet SAML login URL
export const getPhishingUrl = () => {
  const currentUrl = window.location.href;

  if (currentUrl.includes('app.livingsecurity.com')) {
    // PRODUCTION
    return 'https://api.keepnetlabs.com/api/saml/sso/https%3A%2F%2Fidentity.livingsecurity.com';
  } else {
    // DEV & STAGING
    return 'https://api.keepnetlabs.com/api/saml/sso/https%3A%2F%2Fidentity-dev.livingsecurity.com';
  }
};
export const getPhishingApiUrl = () => {
  return 'https://dash.keepnetlabs.com';
};

export const MARKETING_PAGE = {
  TEAMS: 'https://www.livingsecurity.com/cyberescape-online-application',
  PHISHING: 'https://www.livingsecurity.com/enterprise-phishing-simulator-application',
  UNIFY: 'https://www.livingsecurity.com/unify-insights-application',
};
