import styled from 'styled-components';
import { ButtonStyles, SelectStyles, TypographyStyles } from '@livingsecurity/shared';

export const LanguageSelectWrapper = styled.div`
  width: 600px;
  background-color: var(--pine-green-two);
  box-shadow: 0 0 50px 0 var(--aquamarine15);
  border-bottom-right-radius: 100px;
  padding: 60px 75px;

  & > button {
    margin-top: 40px;
    width: 105px;
    height: 45px;
  }

  ${ButtonStyles.ButtonTitle} {
    letter-spacing: 1.5px;
    text-transform: uppercase;
  }

  ${SelectStyles.SelectWrapper} {
    margin-top: 5px;
    font-weight: bold;
    font-size: 16px;
  }

  ${TypographyStyles.Heading3} {
    color: #4ed4b2;
  }

  .ReactSelect {
    &__menu {
      width: 100%;
    }

    &__control {
      width: 100%;
      background-color: var(--black-two15);
    }
  }
`;

export const Subtitle = styled.span`
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: var(--grey);
`;
