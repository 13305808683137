import styled from 'styled-components';

export const Control = styled.div`
  width: ${(props) => (props.animated ? '56px' : '32px')};
  height: ${(props) => (props.animated ? '56px' : '32px')};
  cursor: pointer;
  border-radius: 50%;
  /* debug */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: 0.8s ease all;

  &::after {
    position: absolute;
    left: -16px;
    right: -16px;
    top: -16px;
    bottom: -16px;
    border-radius: ${(props) => (props.shape === 'square' ? '8px' : '100%')};
    border: 4px solid rgba(255, 255, 255, 0);
    content: '';
    transition: 0.6s ease all;
  }

  &:hover::after {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    border: ${(props) => (props.animated ? '4px' : '2px')} solid rgba(255, 255, 255, 1);
  }

  & > svg {
    width: 100%;
    height: 100%;
  }
  & > a {
    width: 100%;
    height: 100%;
  }
`;

export const ControlsSection = styled.div`
  display: flex;
  margin-left: 45px;
  & > * {
    margin-right: 32px;
  }
  & > *:last-child {
    margin-right: 0;
  }
`;
