import React, { useEffect, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { truncate } from 'lodash';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';

import { useToggle, ErrorBoundary, Loader, URLS, Logo, showErrorsFromBackend, useAuth0 } from '@livingsecurity/shared';

import { Badges, NotificationPanel, Sidebar, Stats, Controls, ContentArea } from 'components';
import PageLayout from 'components/layouts/PageLayout';
import LanguageSelect from '../../components/LanguageSelect';
import { ContentViewerEntity } from '../../_entities';

import EpisodesTimeline from './components/EpisodesTimeline';
import ModuleTimeline from './components/ModuleTimeline';
import CongratsScreen from './components/CongratsScreen';

import { useSidebar, useContentViewerProgress, useFakeLoading, useFeedBack, useScreenVisibleState } from '../../hooks';
import { useStandalonePlayerCheck } from '../../hooks/useQueryParams';
import { ViewerContext } from '../../utils/context';
import { CONTENT_VIEWER_TYPES, EVENT_TYPES, SESSION_EVENTS, PROGRESS_STATE } from '../../constants';

import useLoadPage from './hooks';
import * as Styled from './styles';
import Feedback from '../../components/Feedback';

const {
  getContent,
  getContentType,
  getContentId,
  getStats,
  getBadgeData,
  getCampaignId,
  getContentTitles,
  getContentProgress,
  getProgressMap,
  getCompletionDate,
  getAssignmentGradeFlag,
  getCompanyGrade,
  getCompanyGradeFlag,
  getIsLowGrade,
  getIsProgressSaving,
  getHasQuiz,
  getContentTranslations,
  getNextAssignment,
} = ContentViewerEntity.selectors;

const { loadProgress } = ContentViewerEntity.actions;

const ContentViewerPage = ({ flags }) => {
  const { t, i18n } = useTranslation('contentViewer');
  const { user } = useAuth0();

  const {
    params: { id: assignmentId },
  } = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();

  const [isAssessmentLoading, setIsAssessmentLoading] = useState(false);

  const { isStandalonePlayer, isPlatform, isLMS, isContentPersonalized } = useStandalonePlayerCheck();
  const { loading, error, locale, sessionManager } = useLoadPage(); // Load progress and content: ;

  const handleFullscreen = useFullScreenHandle();
  const [isNextNotificationVisible, toggleNotification] = useToggle(false);
  const [showLangSelector, toggleLangSelector] = useToggle(isLMS && !isContentPersonalized);
  const { fakeLoading, startFakeLoading } = useFakeLoading();
  const { isExpanded, handleSidebarExpanded, handleSidebarCollapsed } = useSidebar(false);

  const { isFeedbackAlreadySubmitted, isSubmitting, submitFeedBack } = useFeedBack();

  const {
    isModuleScreenVisible,
    isFeedbackScreenVisible,
    isCongratsScreenVisible,
    showCongratsScreen,
    showFeedbackScreen,
    reset: resetScreen,
  } = useScreenVisibleState('module', ['module', 'feedback', 'congrats']);

  const progress = useSelector(getContentProgress);
  const contentId = useSelector(getContentId);
  const content = useSelector(getContent);
  const campaignId = useSelector(getCampaignId);
  const contentType = useSelector(getContentType);
  const contentTranslations = useSelector(getContentTranslations);
  const { accuracy, progress: progressPercentValue, rank } = useSelector(getStats);
  const { points, dueDate } = useSelector(getBadgeData);
  const { campaignTitle, contentTitle } = useSelector(getContentTitles);
  const progressMap = useSelector(getProgressMap);
  const completionDate = useSelector(getCompletionDate);
  const assignmentGradeFlag = useSelector(getAssignmentGradeFlag);
  const companyGradeFlag = useSelector(getCompanyGradeFlag);
  const companyGrade = useSelector(getCompanyGrade);
  const hasLowGrade = useSelector(getIsLowGrade);
  const isProgressSaving = useSelector(getIsProgressSaving);
  const hasQuiz = useSelector(getHasQuiz);
  const nextAssignment = useSelector(getNextAssignment);

  const { activeModule, nextModule, handleSetNextModule, handleSetActiveModule } = useContentViewerProgress({
    sessionManager,
    onModuleChange: async () => {
      resetScreen();
      if (progressMap?.[activeModule]?.type === 'assessment' && isPlatform) {
        try {
          setIsAssessmentLoading(true);
          await dispatch(loadProgress({ id: assignmentId }));
        } catch (e) {
          showErrorsFromBackend(e);
        } finally {
          setIsAssessmentLoading(false);
        }
        return;
      }
      startFakeLoading();
    },
  });

  const isAvailableNextModule = useMemo(() => nextModule?.status !== PROGRESS_STATE.NOT_AVAILABLE, [nextModule]);

  useEffect(() => {
    const leaderLineSvgs = document.querySelectorAll('svg.leader-line');

    if (isExpanded && leaderLineSvgs.length > 0) {
      leaderLineSvgs.forEach((svg) => (svg.style.display = 'none'));
    } else if (!isExpanded && leaderLineSvgs.length > 0) {
      setTimeout(() => {
        leaderLineSvgs.forEach((svg) => (svg.style.display = 'block'));
      }, 500);
    }
  }, [isExpanded]);

  useEffect(() => {
    if (isLMS) {
      window.userpilot?.destroy();
    }
  }, [isLMS]);

  const redirectToDashboard = useCallback(() => history.replace(URLS.dashboard), [history]);

  const handleSaveProgress = useCallback(
    ({ event, activityId, meta }) => {
      if (isStandalonePlayer) return Promise.resolve(true);
      return sessionManager.saveProgress(event, {
        assignmentId,
        campaignId,
        contentId: activityId.split('_')[1],
        contentParentId: contentId,
        ...meta,
      });
    },

    [assignmentId, campaignId, contentId, isStandalonePlayer, sessionManager],
  );

  const handleRedirect = useCallback(
    (allowRedirect = true) => {
      if (!allowRedirect) return;
      toggleNotification(false);
      if (isFeedbackAlreadySubmitted) {
        if (flags.redirectToCongratsScreen) {
          showCongratsScreen();
        } else {
          redirectToDashboard();
        }
      } else {
        showFeedbackScreen();
      }
    },
    [
      flags.redirectToCongratsScreen,
      isFeedbackAlreadySubmitted,
      redirectToDashboard,
      showCongratsScreen,
      showFeedbackScreen,
      toggleNotification,
    ],
  );

  const handleAssignmentComplete = useCallback(
    (isCompleted = true) => {
      const date = isCompleted ? { completed: new Date().toISOString() } : { attempted: new Date().toISOString() };
      const eventType = isCompleted
        ? SESSION_EVENTS.SESSION_ASSIGNMENT_COMPLETED
        : SESSION_EVENTS.SESSION_ASSIGNMENT_ATTEMPTED;

      sessionManager
        .sessionComplete(eventType, {
          assignmentId,
          campaignId,
          contentId,
          accuracy,
          ...date,
        })
        .then(() => handleRedirect(isPlatform));
    },
    [accuracy, assignmentId, campaignId, contentId, content, handleRedirect, isPlatform, sessionManager],
  );

  const handleFinish = useCallback(() => {
    toggleNotification(false);
    if (isStandalonePlayer) return;
    if (isLMS) {
      handleAssignmentComplete(true);
      return;
    }

    const retakeEnabled = flags?.tmpRetakeAssessments && assignmentGradeFlag && companyGradeFlag;
    const passedGrade = retakeEnabled && hasQuiz ? !hasLowGrade : true;

    if (!completionDate && passedGrade) {
      handleAssignmentComplete(true);
    } else if (!completionDate && !passedGrade) {
      handleAssignmentComplete(false);
    } else {
      handleRedirect();
    }
  }, [
    toggleNotification,
    isStandalonePlayer,
    flags.tmpRetakeAssessments,
    assignmentGradeFlag,
    companyGradeFlag,
    hasQuiz,
    hasLowGrade,
    completionDate,
    isLMS,
    handleAssignmentComplete,
    handleRedirect,
  ]);

  const handleModuleAction = useCallback(() => {
    if (nextModule) {
      handleSetNextModule(activeModule);
      toggleNotification(false);
    } else {
      handleFinish();
    }
  }, [activeModule, handleFinish, handleSetNextModule, nextModule, toggleNotification]);

  const handleFeedbackSubmit = useCallback(
    (feedback) =>
      submitFeedBack(feedback).then(flags.redirectToCongratsScreen ? showCongratsScreen : redirectToDashboard),
    [flags.redirectToCongratsScreen, redirectToDashboard, showCongratsScreen, submitFeedBack],
  );

  const renderTitle = (title = '') => (isExpanded ? truncate(title, { length: 20 }) : title);

  return (
    <ViewerContext.Provider
      value={{
        locale,
        flags,
        isExpanded,
        activeModule,
        nextModule,
        handleSaveProgress,
        handleSetActiveModule,
        showFeedBack: isFeedbackScreenVisible,
      }}
    >
      <FullScreen handle={handleFullscreen}>
        {loading && !isAssessmentLoading ? (
          <Loader />
        ) : (
          <PageLayout>
            <Styled.HeaderContainer
              animated={isExpanded}
              onMouseEnter={handleSidebarExpanded}
              onMouseLeave={handleSidebarCollapsed}
            >
              <Styled.HeaderLeft isExpanded={isExpanded} isStandalonePlayer={!isPlatform}>
                {isPlatform && (
                  <Styled.SubTitleBox animated={isExpanded}>
                    <Logo tenantId={user.tenant_id} reload withNavigation={false} />
                    <Styled.SubTitle data-testid="campaign-title">
                      {`${campaignTitle}` || `${t ? t('not-applicable') : 'N/A'}`}
                    </Styled.SubTitle>
                  </Styled.SubTitleBox>
                )}
                <Styled.Title animated={isExpanded} data-testid="content-title">
                  {`${contentTitle}` || `${t ? t('not-applicable') : 'N/A'}`}
                </Styled.Title>
              </Styled.HeaderLeft>
              <Styled.HeaderRight>
                {isPlatform && <Badges isExpanded={isExpanded} points={points} deadline={dueDate} />}
                <Controls
                  isStandalonePlayer={!isPlatform}
                  isExpanded={isExpanded}
                  flags={flags}
                  handleFullscreen={handleFullscreen}
                />
              </Styled.HeaderRight>
            </Styled.HeaderContainer>
            <Sidebar isExpanded={isExpanded} onMouseEnter={handleSidebarExpanded} onMouseLeave={handleSidebarCollapsed}>
              <Styled.SidebarInner animated={isExpanded}>
                <Styled.TimelineSection isExpanded={isExpanded}>
                  {contentType === CONTENT_VIEWER_TYPES.MODULE && progress && <ModuleTimeline />}
                  {contentType === CONTENT_VIEWER_TYPES.SERIES && progress && <EpisodesTimeline />}
                </Styled.TimelineSection>
                <Stats
                  progress={progressPercentValue}
                  accuracy={accuracy}
                  rank={rank}
                  companyGrade={companyGrade}
                  isRequiredGrade={assignmentGradeFlag && companyGradeFlag}
                  contentType={contentType}
                />
              </Styled.SidebarInner>
            </Sidebar>
            <Styled.ContentView animated={isExpanded}>
              <Styled.ContentInner hideScroll={progressMap?.[activeModule]?.type === 'video'}>
                {error && <div>{t ? t('failed-loading-content') : 'Failed to load content'}</div>}
                {isFeedbackScreenVisible && (
                  <ErrorBoundary scope="Content-Viewer-Feedback">
                    <Feedback
                      onSkip={flags.redirectToCongratsScreen ? showCongratsScreen : redirectToDashboard}
                      onSubmit={handleFeedbackSubmit}
                      loading={isSubmitting}
                      flags={flags}
                    />
                  </ErrorBoundary>
                )}
                {isCongratsScreenVisible && (
                  <ErrorBoundary scope="Content-Viewer-Congrats">
                    <CongratsScreen
                      currentAssignmentTitle={contentTitle}
                      nextAssignment={nextAssignment}
                      onNext={(id) => history.replace(id ? `/assignment/${id}` : URLS.dashboard)}
                    />
                  </ErrorBoundary>
                )}
                {showLangSelector && (
                  <LanguageSelect
                    onSelect={(langCode) => {
                      const params = queryString.parse(history.location.search);
                      params.locale = langCode;
                      const newUrl = `${history.location.pathname}?${queryString.stringify(params)}`;
                      i18n.changeLanguage(langCode);
                      history.replace(newUrl);
                      toggleLangSelector(false);
                    }}
                    contentTranslations={contentTranslations}
                  />
                )}
                {(!showLangSelector && !error && fakeLoading) || isAssessmentLoading ? (
                  <div>{t ? t('loading-content') : 'Loading content...'}</div>
                ) : (
                  !showLangSelector &&
                  progress &&
                  isModuleScreenVisible && (
                    <ContentArea
                      activity={progressMap[activeModule] || {}}
                      toggleNotification={toggleNotification}
                      handleModuleAction={handleModuleAction}
                    />
                  )
                )}
                {isNextNotificationVisible && !fakeLoading && isAvailableNextModule && (
                  <NotificationPanel
                    loading={isProgressSaving}
                    isCompleted={!nextModule}
                    onAction={handleModuleAction}
                    content={nextModule?.content?.title || `${t ? t('not-applicable') : 'N/A'}`}
                    duration={nextModule?.content?.duration}
                  />
                )}
              </Styled.ContentInner>
            </Styled.ContentView>
          </PageLayout>
        )}
      </FullScreen>
    </ViewerContext.Provider>
  );
};

ContentViewerPage.propTypes = {
  flags: PropTypes.shape({}),
};

ContentViewerPage.defaultProps = {
  flags: {},
};

export default ContentViewerPage;
