import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Timeline, TimelineItem, TimelineDot, TimelineContent } from 'components/Timeline';

import { ContentViewerEntity } from '../../../../_entities';
import * as Styled from '../../styles';
import { getIsActivityCompleted } from '../../../../utils';
import { useViewerContext } from '../../../../utils/context';
import { useStandalonePlayerCheck } from '../../../../hooks';
import { PROGRESS_STATE } from '../../../../constants';

const { getModuleContent, getContentId, getProgressMap } = ContentViewerEntity.selectors;

const ModuleTimeLine = () => {
  const { t } = useTranslation('contentViewer');
  const { isExpanded, activeModule, handleSetActiveModule, showFeedBack } = useViewerContext();
  const { contentIdParam, isLMS } = useStandalonePlayerCheck();

  const contentId = useSelector(getContentId) || contentIdParam;
  const moduleContent = useSelector(getModuleContent);
  const progressMap = useSelector(getProgressMap);

  const createModuleKey = useCallback((moduleId) => `${contentId}_${moduleId}`, [contentId]);

  const getIsContentCompleted = useCallback(
    (id) => progressMap[id]?.status === PROGRESS_STATE.COMPLETED,
    [progressMap],
  );

  const handleModuleClick = useCallback(
    ({ id, type }) =>
      () => {
        const moduleId = createModuleKey(id);
        const disableNavForLMS = isLMS && getIsContentCompleted(moduleId);

        if (moduleId === activeModule || showFeedBack || disableNavForLMS) return;
        handleSetActiveModule(moduleId, type);
      },
    [activeModule, createModuleKey, getIsContentCompleted, handleSetActiveModule, isLMS, showFeedBack],
  );

  return (
    <Timeline>
      {!!moduleContent.length &&
        moduleContent.map(({ id, type, content }) => (
          <TimelineItem
            key={createModuleKey(id)}
            active={createModuleKey(id) === activeModule}
            completed={getIsActivityCompleted(progressMap[createModuleKey(id)] || {})}
            onClick={handleModuleClick({ id, type })}
            data-testid={content.title}
          >
            <TimelineDot />
            <TimelineContent animated={isExpanded}>
              <Styled.Duration>
                {content?.duration && `${content?.duration} ${t ? t('minutes-abbreviation') : 'min'}`}
              </Styled.Duration>
              {content?.title || (t ? t('not-applicable') : 'N/A')}
            </TimelineContent>
          </TimelineItem>
        ))}
    </Timeline>
  );
};

ModuleTimeLine.propTypes = {};

export default ModuleTimeLine;
