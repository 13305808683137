import React from 'react';
import PropTypes from 'prop-types';

import * as Styled from './styles';

const Sidebar = ({ children, isExpanded, ...props }) => (
  <Styled.SidebarContainer isExpanded={isExpanded} {...props}>
    {children}
  </Styled.SidebarContainer>
);

Sidebar.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
};

export default Sidebar;
