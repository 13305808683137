import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useToggle } from '@livingsecurity/shared';
import { Timeline, TimelineItem, TimelineDot, TimelineContent } from 'components/Timeline';

import EpisodeTimelineContent from './EpisodeTimelineContent';

import { getEpisodeMeta } from '../../../../utils';
import { useViewerContext } from '../../../../utils/context';
import { ContentViewerEntity } from '../../../../_entities';

import * as Styled from '../../styles';

const { getContentProgress } = ContentViewerEntity.selectors;

const EpisodesTimelineItem = ({ episode }) => {
  const { t } = useTranslation('contentViewer');
  const [isEpisodeExpanded, toggleEpisodeItem] = useToggle(false);

  const { isExpanded } = useViewerContext();
  const progress = useSelector(getContentProgress);

  const { isEpisodeCompleted, isEpisodeActive } = useMemo(
    () => getEpisodeMeta(episode.id, progress),
    [episode.id, progress],
  );

  return (
    <Timeline>
      <TimelineItem
        completed={isEpisodeActive || isEpisodeCompleted}
        onClick={isEpisodeCompleted ? toggleEpisodeItem : undefined}
        root
        big
        data-testid={episode?.episodeContent?.title}
      >
        <TimelineDot />
        <TimelineContent animated={isExpanded}>
          <Styled.Duration>
            {episode?.episodeContent?.duration &&
              `${episode?.episodeContent?.duration} ${t ? t('minutes-abbreviation') : 'min'}`}
          </Styled.Duration>
          <Styled.AssetText>{episode?.episodeContent?.title || (t ? t('not-applicable') : 'N/A')}</Styled.AssetText>
        </TimelineContent>
      </TimelineItem>
      {/* Episode content */}
      {(isEpisodeActive || isEpisodeExpanded) && <EpisodeTimelineContent episode={episode} />}
    </Timeline>
  );
};

export default EpisodesTimelineItem;
