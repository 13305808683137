import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { STAR } from 'assets/icons';

import * as Styled from './styles';

const StarsBlock = ({ count, stars, onChange }) => {
  const { t } = useTranslation('contentViewer');
  const [checkedStarsCount, setCheckedStarsCount] = useState(0);
  useEffect(() => {
    setCheckedStarsCount(stars);
  }, [stars]);

  const handleStarsCount = (newCount) => () => setCheckedStarsCount(newCount);

  const handleChange = useCallback(() => onChange(checkedStarsCount), [checkedStarsCount, onChange]);

  return (
    <Styled.StarsBlock>
      {Array(count)
        .fill(null)
        .map((_, index) => (
          <Styled.StarContainer key={index}>
            <Styled.StarWrapper>
              <Styled.Control
                onMouseOver={handleStarsCount(index + 1)}
                onMouseLeave={handleStarsCount(stars || 0)}
                onClick={handleChange}
                isChecked={checkedStarsCount > index}
              >
                <STAR />
              </Styled.Control>
            </Styled.StarWrapper>
            {index === 0 && <Styled.StarTitle>{t ? t('feedback.star-title.awful') : 'Awful'}</Styled.StarTitle>}
            {index === count - 1 && (
              <Styled.StarTitle>{t ? t('feedback.star-title.amazing') : 'Amazing'}</Styled.StarTitle>
            )}
          </Styled.StarContainer>
        ))}
    </Styled.StarsBlock>
  );
};

StarsBlock.propTypes = {
  count: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  stars: PropTypes.number.isRequired,
};

export default StarsBlock;
