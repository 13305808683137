import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const SidebarContainer = styled.aside`
  min-width: ${(props) => (props.isExpanded ? '390px' : '105px')};
  width: ${(props) => (props.isExpanded ? '390px' : '105px')};
  background-color: ${(props) => (props.isExpanded ? 'transparent' : 'var(--pine-green-two)')};
  overflow-x: ${(props) => (props.isExpanded ? 'auto' : 'unset')};
  transition: 0.8s ease all;
  z-index: 2;
  display: flex;
  flex-direction: column;
`;
