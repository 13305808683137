import React from 'react';
import PropTypes from 'prop-types';

import * as Styled from './styles';

const Badge = ({ color, value, name, topLine, ...rest }) => (
  <Styled.BadgeContainer color={color} {...rest}>
    <Styled.BadgeTop>{topLine}</Styled.BadgeTop>
    <Styled.BadgeValue>{value}</Styled.BadgeValue>
    <Styled.BadgeName>{name}</Styled.BadgeName>
  </Styled.BadgeContainer>
);

Badge.propTypes = {
  color: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  topLine: PropTypes.string,
};

Badge.defaultProps = {
  color: '#2d98d4',
  topLine: '',
};

export default Badge;
