import React, { useCallback, useMemo, useEffect, lazy } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import { LazyLoad, ErrorBoundary } from '@livingsecurity/shared';

import { ContentViewerEntity } from '../../_entities';
import { SESSION_EVENTS, PROGRESS_STATE } from '../../constants';

import { useViewerContext } from '../../utils/context';
import { calculateRatio } from '../../utils';

// TODO remove Quiz stuff when we 100% sure that shared version works well
// const Quiz = lazy(() => import('../Quiz'));
const Quiz = lazy(() => import('../QuizV2'));
const VideoView = lazy(() => import('../VideoView'));
const PuzzleWithDebug = lazy(() => import('./PuzzleWithDebug'));

const LazyQuiz = (props) => <LazyLoad component={Quiz} {...props} />;
const LazyVideoView = (props) => <LazyLoad component={VideoView} {...props} />;
const LazyPuzzleWithDebug = (props) => <LazyLoad component={PuzzleWithDebug} {...props} />;

const {
  getDueDate,
  getCompletionDate,
  getAssignmentProgress,
  getProgressMap,
  getTotalItemsCount,
  getCompletedItemsCount,
  getIsProgressSaving,
} = ContentViewerEntity.selectors;

const ContentArea = ({ activity, handleModuleAction, toggleNotification }) => {
  const { handleSaveProgress, nextModule, flags } = useViewerContext();

  // TODO Too much selectors. Maybe move some stuff to custom hooks?
  const dueDate = useSelector(getDueDate);
  const completionDate = useSelector(getCompletionDate);
  const assignmentProgress = useSelector(getAssignmentProgress);
  const progressMap = useSelector(getProgressMap);
  const totalItemsCount = useSelector(getTotalItemsCount);
  const currentCompletedItemsCount = useSelector(getCompletedItemsCount);
  const isProgressSaving = useSelector(getIsProgressSaving);

  const dispatch = useDispatch();

  const itemStatus = useMemo(() => progressMap[activity.id]?.status, [activity.id, progressMap]);
  const videoTimeRef = useMemo(
    () => (itemStatus !== PROGRESS_STATE.COMPLETED ? progressMap[activity.id]?.meta?.ref ?? 0 : 0),
    [progressMap, activity.id, itemStatus],
  );
  const debugFlag = flags?.debug;

  const isVideoSeekingDisabled = useMemo(() => {
    const isDebugMode = debugFlag || process.env.REACT_APP_DEBUG === 'true';
    const isVideoAlreadyCompleted = itemStatus === PROGRESS_STATE.COMPLETED;
    return !isDebugMode && !isVideoAlreadyCompleted;
  }, [debugFlag, itemStatus]);

  // parse submitted answers and put it in redux. Run it only once
  useEffect(() => {
    if (isEmpty(assignmentProgress)) return;
    // all answers from all quizes in flat structure
    const submittedAnswersFlattened = Object.values(assignmentProgress).reduce((acc, item) => {
      const submittedAnswers = item?.submitAnswers?.map((a) => a.isCorrectAnswer) ?? [];
      return [...acc, ...submittedAnswers];
    }, []);

    if (submittedAnswersFlattened?.length) {
      dispatch(ContentViewerEntity.actions.updateAnswersList(submittedAnswersFlattened));
    }
  }, [assignmentProgress, dispatch]);

  const handleContentCompleted = useCallback(
    (activityId) => {
      if (itemStatus === PROGRESS_STATE.COMPLETED) return;
      dispatch(ContentViewerEntity.actions.updateProgress({ id: activityId, status: PROGRESS_STATE.COMPLETED }));
      const newProgressValue = calculateRatio(currentCompletedItemsCount + 1, totalItemsCount);
      handleSaveProgress({
        event: SESSION_EVENTS.SESSION_CONTENT_COMPLETED,
        activityId,
        meta: { dueDate, completionDate, progress: newProgressValue },
      });
      if (nextModule?.id) {
        dispatch(ContentViewerEntity.actions.updateProgress({ id: nextModule.id, status: PROGRESS_STATE.AVAILABLE }));
      }
    },
    [
      completionDate,
      currentCompletedItemsCount,
      dispatch,
      dueDate,
      handleSaveProgress,
      itemStatus,
      nextModule,
      totalItemsCount,
    ],
  );

  const handleVideoPercentChanged = useCallback(
    (percent, event) => {
      if (itemStatus !== PROGRESS_STATE.COMPLETED) {
        // record video progress according to playedEventPercents
        handleSaveProgress({
          event: SESSION_EVENTS.SESSION_VIDEO_CHECKPOINT,
          activityId: activity.id,
          meta: { refTime: event.Player.currentTime() },
        });
      }

      if (percent >= 80) {
        toggleNotification(true);
        handleContentCompleted(activity.id);
      }
    },
    [activity.id, handleContentCompleted, handleSaveProgress, itemStatus, toggleNotification],
  );

  const handleQuizComplete = useCallback(() => {
    handleContentCompleted(activity.id);
    handleModuleAction();
  }, [activity.id, handleContentCompleted, handleModuleAction]);

  const handlePuzzleEnding = useCallback(
    (passed) => {
      if (passed) {
        handleContentCompleted(activity.id);
        toggleNotification(true);
      }
    },
    [activity.id, handleContentCompleted, toggleNotification],
  );

  return (
    <>
      {activity?.type === 'video' && (
        <ErrorBoundary scope="Content-Viewer-Video">
          <LazyVideoView
            data={activity?.content}
            type={activity?.type}
            onPercentChange={handleVideoPercentChanged}
            disableSeeking={isVideoSeekingDisabled}
            refTime={videoTimeRef}
          />
        </ErrorBoundary>
      )}
      {activity?.type === 'puzzle' && (
        <ErrorBoundary scope="Content-Viewer-Puzzle">
          {/* Temporary enable on dev-server for testing */}
          <LazyPuzzleWithDebug
            puzzleContent={activity?.content}
            handlePuzzleEnding={handlePuzzleEnding}
            flags={flags}
          />
        </ErrorBoundary>
      )}
      {activity?.type === 'assessment' && (
        <ErrorBoundary scope="Content-Viewer-Quiz">
          <LazyQuiz
            title={activity?.content?.title}
            questionsList={activity?.content?.questions}
            onComplete={handleQuizComplete}
            loading={isProgressSaving}
          />
        </ErrorBoundary>
      )}
    </>
  );
};

ContentArea.propTypes = {
  activity: PropTypes.shape(),
  handleModuleAction: PropTypes.func.isRequired,
  toggleNotification: PropTypes.func.isRequired,
};

ContentArea.defaultProps = {
  activity: null,
};

export default ContentArea;
