import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { Quiz as QuizComponent, showErrorsFromBackend } from '@livingsecurity/shared';

import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ContentViewerEntity } from '../../_entities';
import { useViewerContext } from '../../utils/context';

import { calculateRatio } from '../../utils';
import { SESSION_EVENTS } from '../../constants';
import { useStandalonePlayerCheck } from '../../hooks';

const {
  getAssignmentId,
  getAssignmentProgress,
  getAnswersList,
  getCorrectAnswersCount,
  getIsLowGrade,
  getCompanyGrade,
} = ContentViewerEntity.selectors;

const Quiz = ({ title, questionsList, onComplete, loading }) => {
  const { t } = useTranslation('contentViewer');
  const dispatch = useDispatch();
  const { activeModule, handleSaveProgress, flags } = useViewerContext();
  const { isPlatform } = useStandalonePlayerCheck();
  const [progressSavingError, setProgressSavingError] = useState(false);

  const assignmentId = useSelector(getAssignmentId);
  const assignmentProgress = useSelector(getAssignmentProgress);
  const answersList = useSelector(getAnswersList);
  const correctAnswersCount = useSelector(getCorrectAnswersCount);
  const companyGrade = useSelector(getCompanyGrade);
  const hasLowGrade = useSelector(getIsLowGrade);

  const loadedAnswers = useMemo(
    () => get(assignmentProgress, `[${activeModule?.split('_')[1]}].submitAnswers`, []),
    [activeModule, assignmentProgress],
  );

  const isRetakeLimit = [isPlatform, flags?.tmpRetakeAssessments, hasLowGrade].every(Boolean);

  const handleAnswerSubmit = useCallback(
    ({ answerId, isCorrectAnswer, questionId }, skip = false) => {
      const newAccuracy = calculateRatio(correctAnswersCount + Number(isCorrectAnswer), answersList.length + 1);
      return new Promise((resolve) => {
        if (skip) {
          resolve();
        } else {
          handleSaveProgress({
            event: SESSION_EVENTS.SESSION_ANSWER_SUBMITTED,
            activityId: activeModule,
            meta: { selectedContentId: answerId, questionId, isCorrectAnswer, accuracy: newAccuracy },
          })
            .then(() => {
              setProgressSavingError(false);
              resolve();
            })
            .catch(() => {
              setProgressSavingError(true);
              showErrorsFromBackend(t('messages.error.saveProgress'));
            });
        }
      });
    },
    [activeModule, answersList.length, correctAnswersCount, handleSaveProgress, t],
  );

  const onAnswerClick = useCallback(
    ({ answerId, isCorrectAnswer, questionId }) => {
      // upd answers in redux
      dispatch(ContentViewerEntity.actions.updateAnswersList([...answersList, isCorrectAnswer]));
      // submit answer via BE
      return handleAnswerSubmit({ answerId, isCorrectAnswer, questionId });
    },
    [answersList, dispatch, handleAnswerSubmit],
  );

  const onRetakeNow = useCallback(() => {
    dispatch(ContentViewerEntity.actions.updateAnswersList([]));

    // reset submitted answers on the backend
    handleSaveProgress({ event: SESSION_EVENTS.SESSION_RETAKE_QUIZ, activityId: activeModule });

    // need to wait a second for the backend to catch up
    setTimeout(() => {
      // reload assignment progress so the previous Answer List is
      dispatch(ContentViewerEntity.actions.loadProgress({ id: assignmentId }));
    }, 1500);
  }, [activeModule, dispatch, handleSaveProgress, assignmentId]);

  const onNext = useCallback(
    (answer) => handleAnswerSubmit(answer, !progressSavingError), // skip submit retry if no error
    [progressSavingError, handleAnswerSubmit],
  );

  return (
    <QuizComponent
      title={title}
      questionsList={questionsList}
      onComplete={onComplete}
      loading={loading}
      grade={companyGrade}
      loadedAnswers={loadedAnswers}
      isRetakeLimit={isRetakeLimit}
      onRetake={onRetakeNow}
      onAnswerClick={onAnswerClick}
      onNext={onNext}
    />
  );
};

Quiz.propTypes = {
  questionsList: PropTypes.arrayOf(PropTypes.shape()),
  onComplete: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  title: PropTypes.string,
};

Quiz.defaultProps = {
  questionsList: null,
  loading: false,
  title: '',
};

export default Quiz;
