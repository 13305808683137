import { httpClient, convertObjKeysToSnakeCase } from '@livingsecurity/shared';
import { omitBy, pick, isNil } from 'lodash';

import { LmsService } from '../utils/lmsUtils';

import { ContentViewerEntity } from '../_entities';

import { LMS_EVENTS, PLATFORM_EVENTS, SESSION_EVENTS, ASSIGNMENT_ENVIRONMENT } from '../constants';

const { saveProgress, loadProgress } = ContentViewerEntity.actions;

const lmsService = {
  send: (event) => {
    if (!event.session_id) {
      return Promise.reject(new Error('session_id is required'));
    }
    console.log('event is sent to lms-gateway service:', { event });
    // return new Promise((resolve) => setTimeout(() => resolve(event), 1000)); // debug
    return httpClient.post(`/lms-gateway/events-queue`, event);
  },
};

export class SessionManager {
  constructor(env, dispatch, URLParams) {
    this.env = env;
    this.dispatch = dispatch;
    this.URLParams = URLParams;
  }

  isLMS = () => this.env === ASSIGNMENT_ENVIRONMENT.LMS;

  processURLParams = () => {
    const pkgParams = pick(this.URLParams, ['pkgId', 'pkgTimestamp', 'pkgType', 'tenantId']);
    return convertObjKeysToSnakeCase({ ...pkgParams, collection_id: this.URLParams?.contentid });
  };

  processEvent = (eventType, eventPayload) => {
    const safePayload = omitBy(eventPayload, (k) => isNil(k) || k === ''); // remove falsy values
    const pkgMetadata = this.processURLParams();
    if (this.isLMS()) {
      const lmsEvent = convertObjKeysToSnakeCase({
        ...pkgMetadata,
        session_id: LmsService.getSessionId(),
        student_id: LmsService.getStudentId(),
        event_type: LMS_EVENTS[eventType],
        event_payload: convertObjKeysToSnakeCase(safePayload ?? {}),
      });
      return lmsEvent;
    }

    return { ...safePayload, event: PLATFORM_EVENTS[eventType] };
  };

  initializeSession = () => {
    const sid = LmsService.generateSessionId();

    const event = this.processEvent(SESSION_EVENTS.SESSION_ASSIGNMENT_STARTED, {});

    console.log('session initialized:', { sid, event });

    // send init event to the LMS service
    return lmsService.send(event);
  };

  loadProgress = async (id) => {
    if (this.isLMS()) {
      const lmsState = LmsService.getState();
      console.log('loadProgress', { lmsState });

      // generate session id for the first time
      if (!lmsState?.sessionId) {
        await this.initializeSession();
      }

      return lmsState?.progress ?? {};
    }
    const progress = await this.dispatch(loadProgress({ id }));
    return progress;
  };

  trackContentStarted = (eventType, eventPayload) => {
    const event = this.processEvent(eventType, eventPayload);
    if (this.isLMS()) {
      return lmsService.send(event);
    }
    return httpClient.post('/checkpoints', event);
  };

  saveProgress = async (eventType, eventPayload) => {
    const event = this.processEvent(eventType, eventPayload);
    if (this.isLMS()) {
      LmsService.saveProgress({ ...eventPayload, event: eventType });
      // process and send progress event to the LMS service
      console.log('saveProgress', { event, eventType, eventPayload });
      await lmsService.send(event);
      return Promise.resolve(true);
    }
    return this.dispatch(saveProgress(event));
  };

  sessionComplete = (eventType, eventPayload) => {
    const event = this.processEvent(eventType, eventPayload);
    if (this.isLMS()) {
      console.log('sessionComplete', { event, eventType, eventPayload });
      // send complete event to the LMS service
      return lmsService.send(event).finally(LmsService.completeSession);
    }
    return this.dispatch(saveProgress(event));
  };
}
