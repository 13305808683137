import { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useParams } from 'react-router-dom';
import { size, cloneDeep, findLastIndex } from 'lodash';

import { ContentViewerEntity } from '../_entities';

import { PROGRESS_STATE, CONTENT_VIEWER_TYPES, SESSION_EVENTS } from '../constants';
import { getParsedModuleContent, getParsedEpisodes, getProgressItemById } from '../utils';
import { useStandalonePlayerCheck } from './useQueryParams';

const {
  getContentProgress,
  getContentType,
  getContentId,
  getContentData,

  getCampaignId,
  getAssignmentId,
  getAssignmentProgress,
} = ContentViewerEntity.selectors;

const getFlattenedEpisodes = (episodesRaw) => {
  const episodes = getParsedEpisodes(episodesRaw);
  return episodes.reduce((acc, ep) => {
    const { startVideo, endVideo, modules } = ep.content;
    /* istanbul ignore else */
    if (startVideo) {
      acc.push({
        id: `${ep.id}_${startVideo.id}`,
        status: PROGRESS_STATE.NOT_AVAILABLE,
        content: startVideo.content,
        type: startVideo.type,
      });
    }

    modules.forEach((m) => {
      acc.push({
        id: `${ep.id}_${m.id}`,
        status: PROGRESS_STATE.NOT_AVAILABLE,
        content: m.content,
        type: m.type,
      });
    });

    /* istanbul ignore else */
    if (endVideo) {
      acc.push({
        id: `${ep.id}_${endVideo.id}`,
        status: PROGRESS_STATE.NOT_AVAILABLE,
        content: endVideo.content,
        type: endVideo.type,
      });
    }

    return acc;
  }, []);
};

const getInitialModulesProgress = (modulesRaw, contentId) => {
  const modules = getParsedModuleContent(modulesRaw);
  return modules.map((module) => ({
    ...module,
    id: `${contentId}_${module.id}`,
    status: PROGRESS_STATE.NOT_AVAILABLE,
  }));
};

export const useContentViewerProgress = ({ onModuleChange = () => {}, sessionManager } = {}) => {
  const { id: assignmentIdFromURL } = useParams();
  const { contentIdParam, isStandalonePlayer, isPlatform } = useStandalonePlayerCheck();

  const dispatch = useDispatch();

  const progress = useSelector(getContentProgress, shallowEqual);
  const content = useSelector(getContentData);
  const contentType = useSelector(getContentType);
  const contentId = useSelector(getContentId) || contentIdParam;

  const assignmentId = useSelector(getAssignmentId);
  const campaignId = useSelector(getCampaignId);
  const assignmentProgress = useSelector(getAssignmentProgress);

  // "episodeID_activityID" (for series) or "contentID_activityID" (for modules)
  const [activeModule, setActiveModule] = useState(null);

  const isInitialProgressReady = useRef(false);

  useEffect(() => {
    // initialize progress
    if (!progress && contentId && content) {
      // upd status
      const initialProgress =
        contentType === CONTENT_VIEWER_TYPES.SERIES
          ? getFlattenedEpisodes(content?.episodes)
          : getInitialModulesProgress(content, contentId);
      const newProgress = initialProgress.map((item) => ({ ...item, meta: {} }));

      newProgress[0] = { ...newProgress[0], status: PROGRESS_STATE.IN_PROGRESS };
      dispatch(ContentViewerEntity.actions.setInitialProgress(newProgress));
      setActiveModule(newProgress[0].id);
      // const redirectURL = `${url}/${newProgress[0].type}/${newProgress[0].id.split('_')[1]}`;
      // history.replace(redirectURL);
    }
  }, [content, contentId, contentType, dispatch, progress]);

  // TODO refactor this later
  useEffect(() => {
    if (Array.isArray(progress) && size(assignmentProgress) > 0 && activeModule && !isInitialProgressReady.current) {
      const cloneProgress = cloneDeep(progress);
      Object.entries(assignmentProgress).forEach(([id, { status, ...meta }]) => {
        const item = getProgressItemById(id, cloneProgress);
        if (item) {
          item.status = status;
          item.meta = { ...(item.meta || {}), ...meta };
        }
      });
      const lastCompletedItemIdx = findLastIndex(cloneProgress, ['status', PROGRESS_STATE.COMPLETED]);
      const nextAvailableItem = cloneProgress[lastCompletedItemIdx + 1];
      if (nextAvailableItem) {
        nextAvailableItem.status = PROGRESS_STATE.IN_PROGRESS; // manually set status to the next element
        dispatch(ContentViewerEntity.actions.setInitialProgress(cloneProgress));
        setActiveModule(nextAvailableItem.id);
        // const redirectURL = `${url}/${nextAvailableItem.type}/${nextAvailableItem.id.split('_')[1]}`;
        // history.replace(redirectURL);
      } else {
        dispatch(ContentViewerEntity.actions.setInitialProgress(cloneProgress));
        const isAllCompleted = cloneProgress.every((p) => p.status === PROGRESS_STATE.COMPLETED);
        if (isAllCompleted) {
          const lastCompletedItem = cloneProgress[lastCompletedItemIdx];
          setActiveModule(lastCompletedItem.id);
          // const redirectURL = `${url}/${lastCompletedItem.type}/${lastCompletedItem.id.split('_')[1]}`;
          // history.replace(redirectURL);
        }
      }
      // TODO Finish, when checkPoint will work properly
      // if (checkPoint) {
      //   const item = getProgressItemById(checkPoint, cloneProgress);
      //   item && setActiveModule(item.id);
      // }
      isInitialProgressReady.current = true;
    }
  }, [activeModule, assignmentProgress, dispatch, progress]);

  useEffect(() => {
    const activeItem = getProgressItemById(activeModule, progress);
    if (activeItem && activeItem.status === PROGRESS_STATE.IN_PROGRESS && !isStandalonePlayer) {
      sessionManager.trackContentStarted(SESSION_EVENTS.SESSION_CONTENT_STARTED, {
        assignmentId,
        campaignId,
        contentId: activeItem.id.split('_')[1],
      });
      // .then(() => httpClient.get(`/user-content/assignments/${assignmentId}`)); // debug
    }
  }, [activeModule, assignmentId, campaignId, content, isStandalonePlayer, progress, sessionManager]);

  const getNextItem = useCallback(
    (id) => {
      if (!id) return null;
      const currentIdx = progress?.findIndex((p) => p.id === id);
      const nextIdx = currentIdx > -1 ? currentIdx + 1 : null;
      const nextItem = progress && progress[nextIdx];
      return nextItem;
    },
    [progress],
  );

  const handleSetNextModule = useCallback(
    (id) => {
      const nextItem = getNextItem(id);
      dispatch(ContentViewerEntity.actions.updateProgress({ id, status: PROGRESS_STATE.COMPLETED }));
      if (nextItem) {
        // const moduleId = nextItem.id.split('_')[1];
        setActiveModule(nextItem.id);
        // history.replace(`${url}/${nextItem.type}/${moduleId}`);
        dispatch(ContentViewerEntity.actions.updateProgress({ id: nextItem.id, status: PROGRESS_STATE.IN_PROGRESS }));
      } else {
        setActiveModule(null);
      }
    },
    [dispatch, getNextItem],
  );

  const handleSetActiveModule = useCallback(
    (id, type) => {
      const isAvaialable = progress.find((p) => p.id === id)?.status !== PROGRESS_STATE.NOT_AVAILABLE;
      /* istanbul ignore else */
      if (isAvaialable) {
        // const moduleId = id.split('_')[1];
        // history.replace(`${url}/${type}/${moduleId}`);
        setActiveModule(id);
      }
    },
    [progress],
  );

  useEffect(() => {
    return () => {
      if (isPlatform && assignmentIdFromURL) {
        isInitialProgressReady.current = false;
      }
    };
  }, [isPlatform, assignmentIdFromURL]);

  useEffect(() => {
    onModuleChange(activeModule);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeModule]); // don't re-run useEff in case, when cb is new each render. Only watching "activeModule"

  return {
    activeModule,
    handleSetActiveModule,
    handleSetNextModule,
    nextModule: getNextItem(activeModule),
  };
};
