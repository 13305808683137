import * as TYPES from './ContentViewer.types';

/**
 * The Contentful API call to fetch the current content's timeline
 *
 * @param {string} id - The current content's contentful id
 * @param {string} locale - The user's current locale for internationalization
 * @returns {Array<string>} types - Triggers the resulting Redux action
 * @returns {Promise<any>} promise - Of endpoint response
 */
export const loadContentTimeline = ({ id, locale }) => ({
  types: [TYPES.LOAD_CONTENT_TIMELINE, TYPES.LOAD_CONTENT_TIMELINE_SUCCESS, TYPES.LOAD_CONTENT_TIMELINE_FAIL],
  contentful: (client) => client.getEntry(id, { include: 5, locale }),
  payload: {},
});

/**
 * The Contentful API call to fetch the next assignment content
 *
 * @param {string} id - The current content's contentful id
 * @param {string} locale - The user's current locale for internationalization
 * @returns {Array<string>} types - Triggers the resulting Redux action
 * @returns {Promise<any>} promise - Of endpoint response
 */
export const loadNextAssignmentContent = ({ id, contentId, locale }) => ({
  types: [
    TYPES.LOAD_NEXT_ASSIGNMENT_CONTENT,
    TYPES.LOAD_NEXT_ASSIGNMENT_CONTENT_SUCCESS,
    TYPES.LOAD_NEXT_ASSIGNMENT_CONTENT_FAIL,
  ],
  contentful: (client) => client.getEntry(contentId, { locale }),
  payload: {
    id,
    contentId,
  },
});

export const setInitialProgress = (payload) => ({ type: TYPES.SET_INITIAL_PROGRESS, payload });
export const updateProgress = (payload) => ({ type: TYPES.UPDATE_PROGRESS, payload });
export const updateAnswersList = (payload) => ({ type: TYPES.UPDATE_ANSWERS_LIST, payload });

/**
 * The Platform API call to save a user's content progress
 *
 * @param {Object} body - The checkpoint body with assignmentId, campaignId, contentId, contentParentId, event, and refTime
 * @returns {Array<string>} types - Triggers the resulting Redux action
 * @returns {Promise<any>} promise - Of endpoint response
 */
export const saveProgress = (body) => ({
  types: [TYPES.SAVE_CONTENT_PROGRESS, TYPES.SAVE_CONTENT_PROGRESS_SUCCESS, TYPES.SAVE_CONTENT_PROGRESS_FAIL],
  promise: (client) => client.post('/checkpoints', body),
  payload: {},
});

/**
 * The Platform API call to fetch the current content
 *
 * @param {string} id - The current content's id
 * @returns {Array<string>} types - Triggers the resulting Redux action
 * @returns {Promise<any>} promise - Of endpoint response
 */
export const loadProgress = ({ id }) => ({
  types: [TYPES.LOAD_CONTENT_PROGRESS, TYPES.LOAD_CONTENT_PROGRESS_SUCCESS, TYPES.LOAD_CONTENT_PROGRESS_FAIL],
  promise: (client) => client.get(`/user-content/assignments/${id}`),
  payload: {},
});

/**
 * The Platform API call to fetch the current campaign's leaderboard
 *
 * @param {string} id - The current campaign id
 * @returns {Array<string>} types - Triggers the resulting Redux action
 * @returns {Promise<any>} promise - Of endpoint response
 */
export const loadLeaderboardInfo = ({ id }) => ({
  types: [TYPES.LOAD_LEADERBOARD_INFO, TYPES.LOAD_LEADERBOARD_INFO_SUCCESS, TYPES.LOAD_LEADERBOARD_INFO_FAIL],
  promise: (client) => client.get(`/gamification/leaderboard/campaign/${id}`),
  payload: {},
});

/**
 * The Platform API call to fetch a list of campaigns by ID for the assignments on the Player Dashboard
 *
 * @param {Array<object>} body - An object containing a field named "ids" which is an array of campaign ids
 * @returns {Array<string>} types - Triggers the resulting Redux action
 * @returns {Promise<any>} promise - Of endpoint response
 */
export const loadCampaign = (body) => ({
  types: [TYPES.LOAD_CAMPAIGN, TYPES.LOAD_CAMPAIGN_SUCCESS, TYPES.LOAD_CAMPAIGN_FAIL],
  promise: (client) => client.post('/campaigns/batch-get', body),
  payload: {},
});

/**
 * The Platform API call to fetch a single company
 *
 * @param {string} id - A company's id
 * @returns {Array<string>} types - Triggers the resulting Redux action
 * @returns {Promise<any>} promise - Of endpoint response
 */
export const fetchCompany = (id) => ({
  types: [TYPES.FETCH_COMPANY, TYPES.FETCH_COMPANY_SUCCESS, TYPES.FETCH_COMPANY_FAIL],
  promise: (client) => client.get(`/companies/companies/${id}`),
});

export const clearState = () => ({ type: TYPES.CLEAR_STATE });
export const updateAssignmentProgress = (payload) => ({ type: TYPES.UPDATE_ASSIGNMENT_PROGRESS, payload });
